// src/components/LanguageSelector.js
import React from "react";
import { Select } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const handleChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <Select value={i18n.language} onChange={handleChange}>
      <option value="en">English</option>
      <option value="fr">Français</option>
      <option value="es">Español</option>
      <option value="de">German</option>
      <option value="ga">Irish</option>
      <option value="pl">Polish</option>
      <option value="ru">Russian</option>
      <option value="sv">Swedish</option>
      <option value="uk">Ukrainian</option>
      {/* Add other options here */}
    </Select>
  );
};

export default LanguageSelector;
