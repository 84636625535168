import axios from "axios";

const API_URL = "https://core.dev.kiido.app/collaboration-auth/";

const authService = {
  login: async (username, password) => {
    try {
      const response = await axios.post(`${API_URL}login/`, {
        username: username,
        password: password,
      });
      // Return access token, refresh token, and user data
      return {
        accessToken: response.data.tokens.access_token,
        refreshToken: response.data.tokens.refresh_token,
        user: response.data.user,
        professional_profile: response.data.professional_profile,
        association: response.data.association,
      };
    } catch (error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  },

  logout: () => {
    // You may need to implement logout functionality depending on your Django setup.
    // For example, if you're using token-based authentication, you may need to clear the token from local storage.
    throw new Error("Not implemented");
  },
};

export default authService;
