import React, { useState, useEffect } from "react";

import {
  Text,
  Flex,
  Box,
  Icon,
  useColorModeValue,
  Tag,
} from "@chakra-ui/react";
import { FaCalendarAlt, FaClock } from "react-icons/fa";
import BrandHeader from "components/menu/BrandHeader";

// Date Fns
import { format } from "date-fns";

// Mediator
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../actions/collaboratorActions";
import {
  getData,
  getLoading,
  getError,
} from "../../../selectors/collaboratorSelector";
// Router
import { useParams } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { selectUser, selectAccessToken } from "../../../selectors/authSelector";

// Service
import rdvService from "../../../services/rdvService";

// Components
import AppointmentDetails from "./components/AppointmentDetails";

const MediationManagement = ({ user, accessToken }) => {
  // Medation Management [CONFIG BEGINS] ========================
  const [mediatorOptions, setMediatorOptions] = useState([]);
  // Init dispatcher
  const dispatch = useDispatch();
  const collaboratorsData = useSelector(getData);
  console.log(`Collaborators Data is ${collaboratorsData}`);

  // Function to fetch collaborator data
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);
  // Function to assign medaitor data to mediator dropdown from the collaborator data
  useEffect(() => {
    // If not loading and no error
    console.log("collaboratorData", collaboratorsData);
    setMediatorOptions(collaboratorsData);
  }, [collaboratorsData]);
  // Medation Management [CONFIG ENDS] ========================

  // Get the appointment ID from the URL
  // Use the appointmentId as needed
  const { appointmentId } = useParams();

  const [notes, setNotes] = useState(
    "You can add notes here. Notes are auto saved and cannot be edited later."
  );

  // State for loading, error, and data
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  // Function to fetch data from the API
  const fetchRDVData = async () => {
    console.log("fetching rdv data");
    try {
      const res = await rdvService.get({ accessToken, pk: appointmentId });
      // Log
      console.log(res);
      // If the response is not ok, throw an error
      if (!res.data) {
        throw new Error("Network response was not ok");
      }
      // Add raw data to state
      setData(res.data);
    } catch (err) {
      // Log
      console.error(err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!appointmentId) return;
    fetchRDVData();
    // Clean up function to cancel fetch if component unmounts before fetch completes
    return () => {
      // Cleanup logic if needed
    };
  }, [appointmentId]); // Empty dependency array ensures this effect runs only once, similar to componentDidMount


  return (
    <>
      <BrandHeader />
      {/* Ternary for isLoading, error and finally AppointmentDetails */}
       {/*isLoading && <Text>Loading...</Text>*/}
      {error && <Text>{error.message}</Text>}
      {data && (
        <AppointmentDetails
          data={data}
          collaboratorsData={collaboratorsData}
          accessToken={accessToken}
          onStateChange={() => {
            console.log("State update requested --------------->");
             fetchRDVData();
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  accessToken: selectAccessToken(state),
});

export default connect(mapStateToProps)(MediationManagement);
