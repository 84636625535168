export const loginSuccess = ({
  accessToken,
  refreshToken,
  user,
  professional_profile,
  association,
}) => ({
  type: "LOGIN_SUCCESS",
  payload: {
    accessToken,
    refreshToken,
    user,
    professional_profile,
    association,
  },
});

export const loginFailure = (error) => ({
  type: "LOGIN_FAILURE",
  payload: { error },
});

export const logout = () => ({
  type: 'LOGOUT'
});

// Add more action creators for logout, token refresh, etc.
