import axios from "axios";

const API_URL =
  "https://core.dev.kiido.app/collaboration-api/secretary/rdv/session/";

const rdvSessionService = {

  initial: async ({locationId, serviceId, date, accessToken}) => {
    try {
      // Attach the access token to the Authorization header
      const response = await axios.get(`${API_URL}avaibility/?&date=${date}&location=${locationId}&service=${serviceId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Return access token, refresh token, and user data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  },
  get: async ({ pk, accessToken }) => {
    // You may need to implement logout functionality depending on your Django setup.
    throw new Error("Not implemented");
  },

  create: async ({ payload, accessToken }) => {
    try {
      // Attach the access token to the Authorization header
      const response = await axios.post(`${API_URL}`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Return access token, refresh token, and user data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  },

  update: async ({ pk, accessToken, payload }) => {
    // Patch API call for updating an existing resource
    try {
      // Attach the access token to the Authorization header
      // Log the payload to the console
      console.log(`Payload is ${JSON.stringify(payload)}`);
      const response = await axios.patch(`${API_URL}${pk}/`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Return access token, refresh token, and user data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Update failed, please log out and try again.");
    }
  },

  softDelete: async ({ pk, accessToken }) => {
    // Delete API call for deleting an existing resource
    try {
      // Attach the access token to the Authorization header
      const response = await axios.delete(`${API_URL}${pk}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Return access token, refresh token, and user data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Failed to delete the RDV Session.");
    }
  },
};

export default rdvSessionService;
