import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Box, SimpleGrid, FormControl, FormLabel, Input, Select, Textarea, 
  Button, Flex, Text, InputGroup, InputRightElement, Icon
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { selectLocations } from "selectors/authSelector";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';

const NewRequestForm = ({ onSubmit, onClose }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const locationsList = useSelector(selectLocations);

  const [formData, setFormData] = useState({
    name: '',
    status: '',
    sex: '',
    city: '',
    email: '',
    phone: '',
    additionalParticipant1: '',
    additionalParticipant2: '',
    additionalDetails: '',
    date: new Date(),
    time: '',
    service: '',
    location: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^(\+[0-9]{1,3}|0)[0-9]{9,10}$/;
    return re.test(String(phone));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    // Validation
    if (name === 'name') {
      setErrors(prev => ({ ...prev, name: value.trim() ? '' : t('new_request_form.name_required') }));
    } else if (name === 'email') {
      setErrors(prev => ({ ...prev, email: validateEmail(value) ? '' : t('new_request_form.email_invalid') }));
    } else if (name === 'phone') {
      setErrors(prev => ({ ...prev, phone: validatePhone(value) ? '' : t('new_request_form.phone_number_invalid') }));
    }
  };

  const handleDateChange = (date) => {
    setFormData(prev => ({ ...prev, date }));
  };

  const handleTimeChange = (e) => {
    setFormData(prev => ({ ...prev, time: e.target.value }));
  };

  const formatDate = (date) => {
    const day = (`0${date.getDate()}`).slice(-2);
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const isSubmitDisabled = !formData.name || !formData.email || !formData.phone || 
                           errors.name || errors.email || errors.phone;

  const handleSubmit = () => {
    if (isSubmitDisabled) return;
    const formattedData = {
      ...formData,
      date: formatDate(formData.date),
    };
    onSubmit(formattedData);
  };

  return (
    <Box p={6} boxShadow="md" borderRadius="md" bg="white">
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        <FormControl id="location" isRequired>
          <FormLabel>{t('location')}</FormLabel>
          <Select
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            placeholder={t('location')}
            bg="gray.100"
          >
            {locationsList.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl id="service" isRequired>
          <FormLabel>{t('service')}</FormLabel>
          <Select
            name="service"
            value={formData.service}
            onChange={handleInputChange}
            placeholder={t('select_service')}
            bg="gray.100"
          >
            <option value="1">{t('mediation')}</option>
            <option value="2">{t('therapy')}</option>
          </Select>
        </FormControl>

        <FormControl id="name" isRequired isInvalid={!!errors.name}>
          <FormLabel>{t('new_request_form.name')}</FormLabel>
          <Input
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder={t('new_request_form.name')}
            bg="gray.100"
          />
          {errors.name && <Text color="red.500" fontSize="sm">{errors.name}</Text>}
        </FormControl>

        <FormControl id="status">
          <FormLabel>{t('new_request_form.status')}</FormLabel>
          <Select name="status" value={formData.status} onChange={handleInputChange} placeholder={t('new_request_form.status')} bg="gray.100">
            <option value="mother">{t('new_request_form.mother')}</option>
            <option value="father">{t('new_request_form.father')}</option>
            <option value="brother">{t('new_request_form.brother')}</option>
            <option value="sister">{t('new_request_form.sister')}</option>
            <option value="grandmother">{t('new_request_form.grandmother')}</option>
            <option value="grandfather">{t('new_request_form.grandfather')}</option>
            <option value="grandparent">{t('new_request_form.grandparent')}</option>
            <option value="grandson">{t('new_request_form.grandson')}</option>
            <option value="granddaughter">{t('new_request_form.granddaughter')}</option>
            <option value="grandchild">{t('new_request_form.grandchild')}</option>
            <option value="uncle">{t('new_request_form.uncle')}</option>
            <option value="aunt">{t('new_request_form.aunt')}</option>
            <option value="neice">{t('new_request_form.neice')}</option>
            <option value="nephew">{t('new_request_form.nephew')}</option>
            <option value="cousin">{t('new_request_form.cousin')}</option>
            <option value="sister_in_law">{t('new_request_form.sister_in_law')}</option>
            <option value="brother_in_law">{t('new_request_form.brother_in_law')}</option>
            <option value="mother_in_law">{t('new_request_form.mother_in_law')}</option>
            <option value="father_in_law">{t('new_request_form.father_in_law')}</option>
            <option value="fiance">{t('new_request_form.fiance')}</option>
            <option value="friend">{t('new_request_form.friend')}</option>
          </Select>
        </FormControl>

        <FormControl id="sex">
          <FormLabel>{t('new_request_form.sex')}</FormLabel>
          <Select name="sex" value={formData.sex} onChange={handleInputChange} placeholder={t('new_request_form.sex')} bg="gray.100">
            <option value="M">{t('new_request_form.male')}</option>
            <option value="F">{t('new_request_form.female')}</option>
          </Select>
        </FormControl>

        <FormControl id="city">
          <FormLabel>{t('new_request_form.city')}</FormLabel>
          <Input name="city" value={formData.city} onChange={handleInputChange} placeholder={t('new_request_form.city')} bg="gray.100" />
        </FormControl>

        <FormControl id="email" isRequired isInvalid={!!errors.email}>
          <FormLabel>{t('new_request_form.email')}</FormLabel>
          <Input
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            type="email"
            placeholder={t('new_request_form.email')}
            bg="gray.100"
          />
          {errors.email && <Text color="red.500" fontSize="sm">{errors.email}</Text>}
        </FormControl>

        <FormControl id="phone" isRequired isInvalid={!!errors.phone}>
          <FormLabel>{t('new_request_form.phone_number')}</FormLabel>
          <Input
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            type="tel"
            placeholder={t('new_request_form.phone_number')}
            bg="gray.100"
          />
          {errors.phone && <Text color="red.500" fontSize="sm">{errors.phone}</Text>}
        </FormControl>

        <FormControl id="date" isRequired>
          <FormLabel>{t('date')}</FormLabel>
          <InputGroup>
            <DatePicker
              selected={formData.date}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              className="chakra-input css-1c6uqqh"
              showPopperArrow={false}
              placeholderText={t('new_request_form.select_date')}
            />
            <InputRightElement pointerEvents="none">
              <Icon as={FaCalendarAlt} color="gray.500" />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <FormControl id="time" isRequired>
          <FormLabel>{t('time')}</FormLabel>
          <Input
            name="time"
            type="time"
            value={formData.time}
            onChange={handleTimeChange}
            placeholder={t('time')}
            bg="gray.100"
          />
        </FormControl>

        <FormControl id="additional-participant1">
          <FormLabel>{t('new_request_form.additional_participant_1')}</FormLabel>
          <Input name="additionalParticipant1" value={formData.additionalParticipant1} onChange={handleInputChange} placeholder={t('new_request_form.additional_participant_1')} bg="gray.100" />
        </FormControl>

        <FormControl id="additional-participant2">
          <FormLabel>{t('new_request_form.additional_participant_2')}</FormLabel>
          <Input name="additionalParticipant2" value={formData.additionalParticipant2} onChange={handleInputChange} placeholder={t('new_request_form.additional_participant_2')} bg="gray.100" />
        </FormControl>
      </SimpleGrid>

      <FormControl id="additional-details" mt={4}>
        <FormLabel>{t('new_request_form.additional_details')}</FormLabel>
        <Textarea name="additionalDetails" value={formData.additionalDetails} onChange={handleInputChange} placeholder={t('new_request_form.additional_details')} bg="gray.100" />
      </FormControl>

      <Flex justifyContent="flex-end" mt={4}>
        <Button onClick={onClose} mr={3}>{t('cancel')}</Button>
        <Button
          backgroundColor={isSubmitDisabled ? 'gray.300' : '#FCEEEE'}
          color={isSubmitDisabled ? 'gray.500' : '#EE6464'}
          isDisabled={isSubmitDisabled}
          onClick={handleSubmit}
          _hover={!isSubmitDisabled && { bg: '#FFECEC' }}
        >
          {t('submit')}
        </Button>
      </Flex>
    </Box>
  );
};

export default NewRequestForm;
