import axios from "axios";

const API_URL = "https://core.dev.kiido.app/collaboration-api/secretary/rdv";

const rdvService = {
  getAll: async (accessToken, limit = 10, offset = 0) => {
    try {
      // Attach the access token to the Authorization header
      const response = await axios.get(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          limit,
          offset,
        },
      });
      // Return access token, refresh token, and user data
      console.log({getAll: response.data});
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  },

  get: async ({ pk, accessToken }) => {
    try {
      // Attach the access token to the Authorization header
      const response = await axios.get(`${API_URL}/${pk}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Return access token, refresh token, and user data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  },

  new: async(accessToken, formData) => {
    const response = await fetch(`${API_URL.replace("/secretary", "")}/create/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  },

  update: async ({ pk, obj, accessToken }) => {
    // You may need to implement logout functionality depending on your Django setup.
    try {
      // Attach the access token to the Authorization header
      const response = await axios.patch(`${API_URL}/${pk}/`, obj, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Return access token, refresh token, and user data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  },

  updateParticipantInfo: async({rdvId, participantId, obj, accessToken}) =>
  {
     try {
      // Attach the access token to the Authorization header
      const response = await axios.patch(`${API_URL}/${rdvId}/participant/${participantId}`, obj, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Return access token, refresh token, and user data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  },


  updateChildInfo: async({rdvId, childId, obj, accessToken}) =>
  {
     try {
      // Attach the access token to the Authorization header
      const response = await axios.patch(`${API_URL}/${rdvId}/child/${childId}`, obj, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Return access token, refresh token, and user data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  },

  deleteParticipant: async ({ pk, updatedParticipants, accessToken }) => {
    // Log
    console.log("Updated Participants: ", updatedParticipants);

    // Attach the access token to the Authorization header
    const response = await axios.patch(
      `${API_URL}/${pk}/participant/delete`,
      updatedParticipants,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // Log
    console.log("Response: ", response);

    if (response.status !== 200) {
      console.log("Throwing error | SERVICE");
      throw new Error(response.data);
    }
    // Return access token, refresh token, and user data
    return {
      data: response.data,
    };
  },

  addParticipant: async ({ pk, participant, accessToken }) => {
    try {
      // Attach the access token to the Authorization header
      const response = await axios.post(
        `${API_URL}/${pk}/participant`,
        participant,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // Return access token, refresh token, and user data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  },

  addChild: async ({ pk, child, accessToken }) => {
    try {
      // Attach the access token to the Authorization header
      const response = await axios.post(`${API_URL}/${pk}/child/`, child, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // Return access token, refresh token, and user data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Login failed. Please check your credentials.");
    }
  },

  softDelete: ({ rdv_id }) => {
    // You may need to implement logout functionality depending on your Django setup.
    throw new Error("Not implemented");
  },

  search: async (accessToken, query, limit = 10, offset = 0) => {
    try {
      console.log('search query is');
      console.log(query)
      console.log(`${API_URL}/${query}`);
      const response = await axios.get(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {...query, limit, offset},
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Search failed. Please check your credentials.");
    }
  },

  filter: async (accessToken, query, limit = 10, offset = 0) => {
    console.log('search query is');
      console.log(query)

    try {
      const response = await axios.get(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {...query, limit, offset},
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("filter failed. Please check your credentials.");
    }
  },
};

export default rdvService;
