import React, { Suspense } from "react";
import { useState } from "react";

import {
  Text,
  Flex,
  Box,
  Icon,
  Button,
  Link,
  Center,
  Divider,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  Avatar,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";

// Language selector
import LanguageSelector from "./LanguageSelector";

// Language Management
import { useTranslation } from "react-i18next";

import { MdSecurity } from "react-icons/md";

// Redux
import { connect } from "react-redux";
import {
  selectUser,
  selectAssociation,
  selectProfessionalProfile,
} from "selectors/authSelector";
import { logout } from 'actions/authActions';
import { useDispatch } from 'react-redux';
// Router
import { useHistory } from "react-router-dom";

const activeButtonStyle = {
  backgroundColor: "#FF6666",
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "8px",
  cursor: "pointer",
};

const inactiveButtonStyle = {
  backgroundColor: "white",
  color: "#252525",
  border: "none",
  borderRadius: "4px",
  padding: "8px",
  cursor: "pointer",
};

const activeTabStyle = {
  textDecoration: "none",
  color: "#FF6666",
  cursor: "pointer",
};

const inactiveTabStyle = {
  textDecoration: "none",
  color: "#252525",
  cursor: "pointer",
};

const disabledStyle = {
  textDecoration: "none",
  color: "#888888",
  cursor: "pointer",
};

const CBrandHeader = ({ association, user, professionalProfile }) => {
  // Init i18n
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("appointments");

  // Init History
  const history = useHistory();

  // Other

  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  return (
    <Box>
      <Flex direction={"column"}>
        <Flex justify={"space-between"} mx={8} py={1}>
          <Box>
            <Image
              src="https://kiido-media.s3.eu-west-3.amazonaws.com/Kiido_Logo_Original+(1).png"
              alt="Kiido Logo"
              sx={{
                height: "50px",
                width: "auto",
              }}
            />
          </Box>
          <Flex
            style={{
              alignItems: "center",
              fontSize: "18px",
              fontWeight: "bold",
              gap: "24px",
            }}
          >
            <Link sx={disabledStyle} cursor={"none"}>
              {t("admin__header__mediator")}
            </Link>
            <Link sx={disabledStyle} cursor={"none"}>
              {t("admin__header__services")}
            </Link>
            <Link sx={disabledStyle} cursor={"none"}>
              {t("admin__header__locations")}
            </Link>
            <Link
              sx={
                activeTab === "appointments" ? activeTabStyle : inactiveTabStyle
              }
              onClick={() => {
                setActiveTab("appointments");
                // Navigate to /admin/default
                history.push("/admin/default");
              }}
            >
              {t("admin__header__requests")}
            </Link>
          </Flex>
          <Flex gap={16}>
            <Flex height={"fit-content"} my={"auto"}>
              {/* Language Selector */}
              <LanguageSelector />
            </Flex>
            <Box my={"auto"}>
              <Menu>
                <MenuButton p="0px">
                  <Avatar
                    _hover={{ cursor: "pointer" }}
                    color="white"
                    name={user?.full_name ?? "User"}
                    bg="#11047A"
                    size="sm"
                    w="40px"
                    h="40px"
                  />
                </MenuButton>
                <MenuList
                  boxShadow={shadow}
                  p="0px"
                  mt="10px"
                  borderRadius="20px"
                  bg={menuBg}
                  border="none"
                >
                  {/* Flex with horizontally centered */}
                  <Flex
                    w="100%"
                    mb="0px"
                    justifyContent={"center"}
                    paddingTop={"5px"}
                  >
                    <Avatar
                      _hover={{ cursor: "pointer" }}
                      color="white"
                      name={user?.full_name ?? "User"}
                      bg="#11047A"
                      size="sm"
                      w="40px"
                      h="40px"
                      bgImage={professionalProfile?.display_picture}
                    />

                    {/* <AvatarEditor
                      image={professionalProfile?.display_picture}
                      width={60}
                      height={60}
                      border={50}
                      color={[255, 255, 255, 0.6]} // RGBA
                      scale={1.2}
                      rotate={0}
                    /> */}
                  </Flex>
                  <Flex w="100%" mb="0px">
                    <Text
                      ps="20px"
                      pt="16px"
                      pb="10px"
                      w="100%"
                      borderBottom="1px solid"
                      borderColor={borderColor}
                      fontSize="sm"
                      fontWeight="700"
                      color={textColor}
                      justifyContent={"center"}
                    >
                      👋&nbsp; {t("admin__home__greet")},{" "}
                      {user?.full_name ?? "User"}
                    </Text>
                  </Flex>
                  <Flex flexDirection="column" p="10px">
                    <MenuItem
                      _hover={{ bg: "none" }}
                      _focus={{ bg: "none" }}
                      borderRadius="8px"
                      px="14px"
                    >
                      <Text fontSize="sm">{user?.email}</Text>
                    </MenuItem>
                    <MenuItem
                      _hover={{ bg: "none" }}
                      _focus={{ bg: "none" }}
                      borderRadius="8px"
                      px="14px"
                    >
                      <Text fontSize="sm">{t("admin__home__timezone")}</Text>
                    </MenuItem>
                    <MenuItem
                      _hover={{ bg: "none" }}
                      _focus={{ bg: "none" }}
                      borderRadius="8px"
                      px="14px"
                    >
                      <Image
                        src={association?.logo}
                        alt={`${association?.name}'s logo`}
                        sx={{ height: "30px", width: "auto" }}
                        margin={"auto"}
                      />
                    </MenuItem>
                    <MenuItem
                      _hover={{ bg: "none" }}
                      _focus={{ bg: "none" }}
                      borderRadius="8px"
                      px="14px"
                    >
                      <Flex>
                        <Text fontSize="sm">
                          {" "}
                          <span>
                            <MdSecurity />
                            {t("admin__home__safe_datacentre")}
                          </span>
                        </Text>
                      </Flex>
                    </MenuItem>
                    <MenuItem
                      _hover={{ bg: "none" }}
                      _focus={{ bg: "none" }}
                      borderRadius="8px"
                      px="14px"
                    >
                      <Text fontSize="sm">
                        {t("admin__home__subscription__prompt")} -{" "}
                        <strong>{t("admin__home__subscription__value")}</strong>
                      </Text>
                    </MenuItem>
                    <MenuItem
                        _hover={{ bg: "none" }}
                        _focus={{ bg: "none" }}
                        color="red.400"
                        borderRadius="8px"
                        px="14px"
                        onClick={() => {
                          dispatch(logout());
                          history.push("/auth/sign-in");
                        }}
                      >
                      <Text fontSize="sm">{t("admin__home__sign_out")}</Text>
                    </MenuItem>
                  </Flex>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
        </Flex>
        <Flex px={8} py={3} borderY={"solid 1px #E7E7E7"} alignItems={"center"}>
          <Image
            src={association?.logo}
            alt=""
            sx={{
              height: "50px",
              width: "auto",
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  association: selectAssociation(state),
  user: selectUser(state),
  professionalProfile: selectProfessionalProfile(state),
});

const ConnectedbrandHeader = connect(mapStateToProps)(CBrandHeader);

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function BrandHeader() {
  return (
      <ConnectedbrandHeader />
  );
}
