import React, { useState, useEffect } from "react";
import ComplexTable from "views/admin/default/components/ComplexTable";
import { allEventsColumnsData } from "views/admin/default/variables/allEventsColumnsData";

import Card from "components/card/Card";
import { Flex, Text } from "@chakra-ui/react";

// Yet another table
import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";

// Service
import rdvService from "services/rdvService";

// Dummy
import { columnsDataDevelopment } from "views/admin/dataTables/variables/columnsData";

function Primer({ text }) {
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text fontSize="lg" fontFamily="heading" fontWeight="500">
          {text}
        </Text>
      </Flex>
    </Card>
  );
}

function AppointmentsController({ accessToken, detailed }) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rawData, setRawData] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const response = rdvService
      .getAll(accessToken)
      .then((res) => {
        // Add raw data to state
        setRawData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });

    // Clean up function to cancel fetch if component unmounts before fetch completes
    return () => {
      // Cleanup logic if needed
    };
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  if (isLoading) {
    return <Primer text="Loading..." />;
  }

  if (error) {
    return <Primer text={`Error: ${error}`} />;
  }

  // If detailed is true, return the DevelopmentTable component
  if (detailed) {
    return (
      <DevelopmentTable
        columnsData={columnsDataDevelopment}
        tableData={rawData}
      />
    );
  }
  return (
    <ComplexTable columnsData={allEventsColumnsData} tableData={rawData} />
  );
}

export default AppointmentsController;
