import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Switch,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import rdvSessionService from "services/rdvSessionService";
import rdvService from "services/rdvService";
import CustomModal from "./CustomModal"; // Import the CustomModal component

const CCreateFirstSessionDialog = ({
  isOpen,
  onClose,
  accessToken,
  originalRdvId,
  liftStateUp,
  availableLocations,
  preferredLocation,
  proposedDate,
  proposedTime,
  serviceId,
  isFirstSession,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(proposedDate || "");
  const [startTime, setStartTime] = useState(proposedTime || "");
  const [sessionType, setSessionType] = useState("Info");
  const [location, setLocation] = useState(preferredLocation ? preferredLocation.id : "");
  const [editMode, setEditMode] = useState(false);
  const [mediator, setMediator] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchTimeSlots(location, startDate);
    }
  }, [isOpen, location, serviceId, startDate, accessToken]);

  const fetchTimeSlots = async (locationId, date) => {
    try {
      const response = await rdvSessionService.initial({
        locationId,
        serviceId,
        date,
        accessToken,
      });

      console.log(response.data);
      setTimeSlots(response.data[date] || []);
    } catch (error) {
      console.error("Error fetching initial data", error);
    }
  };

  const handleSlotSelect = (e) => {
    const selectedIndex = parseInt(e.target.value);
    const selectedSlot = timeSlots[selectedIndex];
    if (selectedSlot) {
      setMediator(selectedSlot.service_provider.id.toString());
      setStartTime(selectedSlot.start_time);
      setSelectedSlot(selectedSlot);
    }
  };

  const handleLocationChange = (e) => {
    const newLocation = e.target.value;
    console.log(newLocation);
    setLocation(newLocation);
    fetchTimeSlots(newLocation, startDate);
  };

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setStartDate(newDate);
    fetchTimeSlots(location, newDate);
  };

  const handleSubmit = async () => {
    const startDateTime = new Date(`${startDate}T${startTime}`);
    const endDateTime = new Date(startDateTime.getTime() + 3600000); // 1 hour later

    try {
      await rdvSessionService.create({
        payload: {
          session_title: title,
          start_date: startDateTime,
          end_date: endDateTime,
          type: sessionType,
          orginial_rdv: originalRdvId,
          service_provider: parseInt(mediator),
          location: parseInt(location),
          service_provider: mediator,
        },
        accessToken,
      });
      await updateRdv();
      resetForm();
      liftStateUp();
      onClose();
    } catch (error) {
      console.error("Error creating session", error);
    }
  };

  const updateRdv = async () => {
    try {
      await rdvService.update({
        pk: originalRdvId,
        obj: {
          mediator: parseInt(mediator),
        },
        accessToken,
      });
    } catch (error) {
      console.error("Error updating RDV", error);
    }
  };

  const resetForm = () => {
    setTitle("");
    setStartDate(proposedDate || "");
    setStartTime(proposedTime || "");
    setSessionType("Info");
    setLocation(preferredLocation ? preferredLocation.id : "");
    setMediator("");
    setEditMode(false);
    setSelectedSlot(null);
  };

  return (
    <CustomModal isOpen={isOpen} onClose={() => { resetForm(); onClose(); }}>
      <FormControl>
        <FormLabel>{t("name_for_session")}</FormLabel>
        <Input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={t("enter_name")}
        />
      </FormControl>

      <FormControl mt={4} isDisabled={isFirstSession && !editMode}>
        <FormLabel>{t("select_session_type")}</FormLabel>
        <Select
          value={sessionType}
          onChange={(e) => setSessionType(e.target.value)}
          placeholder={isFirstSession ? "Select session type" : undefined}
        >
          {isFirstSession ? (
            <>
              <option value="Info">{t("info")}</option>
              <option value="Mediation">{t("mediation")}</option>
            </>
          ) : (
            <option value="Mediation">{t("mediation")}</option>
          )}
        </Select>
      </FormControl>

      <FormControl mt={4} isDisabled={isFirstSession && !editMode}>
        <FormLabel>
          {editMode ? t("select_location") : t("selected_location")}
        </FormLabel>
        <Select
          value={location}
          onChange={handleLocationChange}
        >
          {availableLocations.map((loc) => (
            <option key={loc.id} value={loc.id}>
              {loc.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl mt={4} isDisabled={isFirstSession && !editMode}>
        <FormLabel>
          {editMode ? t("select_session_date") : t("selected_session_date")}
        </FormLabel>
        <Input
          type="date"
          value={startDate}
          onChange={handleDateChange}
        />
      </FormControl>

      <FormControl mt={4} isDisabled={isFirstSession && !editMode}>
        <FormLabel>
          {editMode ? t("select_time_slot") : t("selected_time_slot")}
        </FormLabel>
        <Input
          type="time"
          value={startTime}
          readOnly
        />
      </FormControl>

      <FormControl mt={4}>
        <FormLabel>
          {editMode ? t("select_mediator_and_time_slot") : t("selected_mediator_and_time_slot")}
        </FormLabel>
        <Select
          value={timeSlots.findIndex(slot =>
            (slot.start_time === startTime && slot.service_provider.id.toString() === mediator)
          )}
          onChange={handleSlotSelect}
          isDisabled={isFirstSession && !editMode}
        >
          {timeSlots.map((slot, index) => (
            <option key={index} value={index}>
              {`${slot.service_provider.first_name} ${slot.service_provider.last_name} (${slot.start_time} - ${slot.duration})`}
            </option>
          ))}
        </Select>
      </FormControl>

      {isFirstSession && (
        <FormControl display="flex" alignItems="center" mt={4}>
          <FormLabel htmlFor="edit-mode-toggle" mb="0">
            {t("edit_details")}
          </FormLabel>
          <Switch
            id="edit-mode-toggle"
            isChecked={editMode}
            onChange={() => setEditMode(!editMode)}
          />
        </FormControl>
      )}
      <Button colorScheme="blue" mr={3} onClick={handleSubmit} mt={4}>
        {t("create_session")}
      </Button>
      {/*<Button variant="ghost" onClick={() => { resetForm(); onClose(); }} mt={4}>
        {t("cancel")}
      </Button>*/}
    </CustomModal>
  );
};

export default function CreateFirstSessionDialog({
  isOpen,
  onClose,
  accessToken,
  originalRdvId,
  liftStateUp,
  availableLocations,
  preferredLocation,
  proposedDate,
  proposedTime,
  serviceId,
  isFirstSession,
}) {
  return (
    <CCreateFirstSessionDialog
      isOpen={isOpen}
      onClose={onClose}
      accessToken={accessToken}
      originalRdvId={originalRdvId}
      liftStateUp={liftStateUp}
      availableLocations={availableLocations}
      preferredLocation={preferredLocation}
      proposedDate={proposedDate}
      proposedTime={proposedTime}
      serviceId={serviceId}
      isFirstSession={isFirstSession}
    />
  );
}
