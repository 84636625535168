import React, { useState, useEffect } from "react";
import { Checkbox, Stack, Text } from "@chakra-ui/react";

export default function MultiSelectCheckbox({
  checkBoxItems,
  parentName,
  liftStateUp,
  preSelectedIds,
  disableEdit,
}) {
  const [checkedItems, setCheckedItems] = useState(
    new Array(checkBoxItems.length).fill(false)
  );
  const [selectedItemIds, setSelectedItemIds] = useState([]);

  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  //   Call the liftStateUp function to pass the checked items to the parent component
  useEffect(() => {
    // Logic to pass the selected item ids to the parent component
    liftStateUp(selectedItemIds);
  }, [selectedItemIds, liftStateUp]);

  return (
    <>
      <Checkbox
        isChecked={allChecked}
        isIndeterminate={isIndeterminate}
        isDisabled={disableEdit}
        onChange={(e) => {
          const newCheckedItems = new Array(checkBoxItems.length).fill(
            e.target.checked
          );
          setCheckedItems(newCheckedItems);
          liftStateUp(newCheckedItems);
        }}
      >
        <Text fontWeight="bold" fontSize={"16px"} color={"#FF3E3E"}>
          {parentName}
        </Text>
      </Checkbox>
      <Stack pl={6} mt={1} spacing={1}>
        {checkBoxItems.map((item, index) => (
          <Checkbox
            isChecked={checkedItems[index] || preSelectedIds.includes(item.id)}
            isDisabled={disableEdit}
            onChange={(e) => {
              const newCheckedItems = [...checkedItems];
              newCheckedItems[index] = e.target.checked;
              setCheckedItems(newCheckedItems);
              if (e.target.checked)
                setSelectedItemIds([...selectedItemIds, item.id]);
              else
                setSelectedItemIds(
                  selectedItemIds.filter((id) => id !== item.id)
                );
            }}
            key={index.id}
          >
            {item.name}
          </Checkbox>
        ))}
      </Stack>
    </>
  );
}
