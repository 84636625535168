import axios from 'axios';

const API_URL = 'https://core.dev.kiido.app';  // Replace with your actual API URL

const documentService = {
  getAll: async (sessionId, accessToken) => {
    try {
      const response = await axios.get(`${API_URL}/collaboration-api/sessions/${sessionId}/documents`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch documents.');
    }
  },

  startUpload: async (filename,fizesize, filetype, display_name, accessToken) => {
//     console.log(file.name);
    try {
      const response = await axios.post(
        `${API_URL}/collaboration-api/upload/direct/start/`,
        {
          file_name: filename,
          file_type: filetype,
          metadata: {
            uploaded_from: 'vault',
          },
          category: 'PS',
          display_name: display_name,
          household: null,
          shared_with: [],
          size: fizesize,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error('Failed to start upload.');
    }
  },

  uploadToS3: async (url, file) => {
    try {
      await axios.put(url, file);
    } catch (error) {
      throw new Error('Failed to upload to S3.');
    }
  },
//   directUploadDo : ({ data, file }) => {
//     const postData = new FormData();
//
//     postData.append('file', file);
//
// //     let postParams = getConfig();
//
//     // If we're uploading to S3, detach the authorization cookie.
//     // Otherwise, we'll get CORS error from S3
// //     if (data?.fields) {
// //       postParams = {};
// //     }
// /*
//     return axios
//       .post(data, postData, postParams)
//       .then(() => Promise.resolve({ fileId: data.id }));*/
//   },
  finishUpload: async (fileId, accessToken) => {
    try {
      await axios.post(
        `${API_URL}/collaboration-api/upload/direct/finish/`,
        {
          file_id: fileId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      throw new Error('Failed to finish upload.');
    }
  },

  rename: async (documentId, data, accessToken) => {
    try {
      const response = await axios.patch(`${API_URL}/documents/${documentId}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to rename document.');
    }
  },

  delete: async (documentId, accessToken) => {
    try {
      const response = await axios.delete(`${API_URL}/documents/${documentId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to delete document.');
    }
  },
};

export default documentService;
