export const columnsDataDevelopment = [
  {
    Header: "Mediator",
    accessor: "mediator",
  },
  {
    Header: "File Number",
    accessor: "file_number",
  },
  {
    Header: "Nature",
    accessor: "nature",
  },
  {
    Header: "Legal Context",
    accessor: "legal_context",
  },
  {
    Header: "Subject",
    accessor: "subject",
  },
  {
    Header: "Participants",
    accessor: "participants",
  },
  {
    Header: "Association",
    accessor: "association",
  },
  {
    Header: "Location",
    accessor: "location",
  },
  {
    Header: "Service",
    accessor: "service",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Time",
    accessor: "time",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
