/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Divider,
  MenuDivider,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import Menu from "components/menu/MainMenu";
import React, { useMemo, useEffect, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";


// History / Navigator
import { useHistory } from "react-router-dom";

// Collaborator Selector
import {
  getData,
  getError,
  getLoading,
} from "../../../../selectors/collaboratorSelector";

import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../../actions/collaboratorActions";

export default function DevelopmentTable(props) {
  // Init history
  const history = useHistory();

  // Others
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const [mediatorOptions, setMediatorOptions] = useState([]);

  // Init dispatcher
  const dispatch = useDispatch();
  const collaboratorData = useSelector(getData);
  const loading = useSelector(getLoading);
  const error = useSelector(getError);

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  // Function to assign medaitor data to mediator dropdown from the collaborator data
  useEffect(() => {
    // If not loading and no error
    console.log("collaboratorData", collaboratorData);
    setMediatorOptions(collaboratorData);
  }, [collaboratorData]);

  // Function to handle mediator change
  const handleMediatorChange = (index, newValue) => {
    console.log("index", index);
    console.log("newValue", newValue);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          All RDVs
        </Text>
        <Menu />
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "Mediator") {
                    data = (
                      <Flex justify="space-between" align="center">
                        <Button
                          fontSize="sm"
                          variant="brand"
                          fontWeight="500"
                          w="100%"
                          h="50"
                          onClick={() =>
                            history.push(
                              `/admin/appointment-checkin/${row.original.id}`
                            )
                          }
                        >
                          Manage
                        </Button>
                      </Flex>
                    );
                  } else if (
                    cell.column.Header === "Location" ||
                    cell.column.Header === "Service"
                  ) {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="400">
                        {cell.value.name}
                      </Text>
                    );
                  } else if (cell.column.Header === "Participants") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="400">
                        {/* Comma separated - participant names */}
                        {cell.value.map((participant, index) => {
                          return (
                            <>
                              <Text key={index} fontSize="sm" fontWeight="400">
                                {participant.name}
                              </Text>
                              {/* Add divider if this is not the last participant */}
                              {index !== cell.value.length - 1 && (
                                <Divider
                                  orientation="horizontal"
                                  borderColor={borderColor}
                                />
                              )}
                            </>
                          );
                        })}
                      </Text>
                    );
                  } else {
                    // Return the cell value in Text component
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="400">
                        {JSON.stringify(cell.value)}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
