import axios from "axios";

const API_URL = "https://core.dev.kiido.app/collaboration-api";

const locationService = {
  getAll: async (associationId) => {
    try {
      // Attach the access token to the Authorization header
      const response = await axios.get(`${API_URL}/cithea/location/`);
      // Log
      console.log("Location data: ", response.data);
      // Return access token, refresh token, and user data
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Unable to fetch locations.");
    }
  },
};

export default locationService;
