import React, { useState, useEffect } from "react";
import {
  Flex,
  Button,
  Box,
  Grid,
  GridItem,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Select,
  Divider,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { format } from 'date-fns';

import { ChevronDownIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import TrixEditor from "./RichTextEditor";
import MultiSelectCheckbox from "./MultiCheckbox";
import GenericAlertDialog from "./GenericAlertDialog";
import CreateFirstSessionDialog from "./CreateFirstSessionDialog";
import DocumentsTab from "./DocumentsTab";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import rdvSessionService from "services/rdvSessionService";
import rdvService from "services/rdvService";
import locationService from "services/locationService";
import { MdDelete, MdCancel } from "react-icons/md";

const activeTabStyle = {
  color: "#FF6666",
  fontSize: "18px",
  fontWeight: "600",
  borderBottom: "2px solid #FF6666",
};

const inactiveTabStyle = {
  color: "#565656",
  fontSize: "18px",
  fontWeight: "600",
};

const CSessions = ({
  collaboratorsData,
  appointmentData,
  accessToken,
  onStateChange,
}) => {
  const [selectedSession, setSelectedSession] = useState(
    appointmentData.rdv_sessions[0]
  );

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("sessionDetails");
  const [isFirstSessionDialogOpen, setIsFirstSessionDialogOpen] = useState(false);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(appointmentData.location);
  const [participantsForRDV, setParticipantsForRDV] = useState([]);
  const [mediator, setMediator] = useState(t("click_to_select"));

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [alertDialogTitle, setAlertDialogTitle] = useState("");
  const [alertDialogBody, setAlertDialogBody] = useState("");
  const [alertDialogOnClose, setAlertDialogOnClose] = useState(() => () => {});
  const [alertDialogConfirm, setAlertDialogConfirm] = useState(() => () => {});

  useEffect(() => {
    locationService.getAll(appointmentData.association).then((response) => {
      setAvailableLocations(response.data);
    });
  }, [appointmentData.association]);

  const openDialog = (title, body, confirm, close) => {
    setAlertDialogTitle(title);
    setAlertDialogBody(body);
    setAlertDialogConfirm(() => confirm);
    setAlertDialogOnClose(() => close);
    onOpen();
  };

  const handleConfirm = () => {
    alertDialogConfirm();
    onClose();
  };

  const handleClose = () => {
    alertDialogOnClose();
    onClose();
  };

  const updateRdvSession = async ({ payload }) => {
    try {
      await rdvSessionService.update({
        pk: selectedSession.id,
        payload,
        accessToken,
      });
      onStateChange();
    } catch (error) {
      console.error(t("select_appropriate_location_and_participants"));
    }
  };

  const triggerPromiseBasedToast = () => {
    toast.promise(
      updateRdvSession({
        payload: {
          participants: participantsForRDV,
          location: selectedLocation,
        },
      }),
      {
        pending: `${t("inviting_participants")} 📧`,
        success: `${t("participants_invited")} 👌`,
        error: `${t("failed_to_invite")} 🤯`,
        position: "bottom-center",
        variant: "subtle",
        backgroundColor: "#FF6666",
        color: "white",
      }
    );
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {appointmentData.rdv_sessions.length === 0 ? (
        <Flex direction={"column"} height={"40vh"} overflowY={"auto"} padding={4} marginTop={4}>
          <Button
            fontSize={"18px"}
            fontWeight={"300"}
            mx={"auto"}
            color={"#FF6666"}
            bgColor={"#FFEEEE"}
            padding={"8px 16px"}
            borderRadius={"4px"}
            isDisabled={appointmentData.is_closed}
            mt={4}
            onClick={() => setIsFirstSessionDialogOpen(true)}
          >
            {t("add_new_session")}
          </Button>
        </Flex>
      ) : (
        <Grid templateRows="100px 1fr">
          <GridItem>
            <Box overflowX="scroll" display="flex" alignItems="center">
              <Flex alignItems={"stretch"}>
                <>
                  {appointmentData.rdv_sessions.map((session, index) => (
                    <Box
                      key={session.id}
                      minWidth="250px"
                      borderWidth="1px"
                      borderRadius="md"
                      padding="4"
                      marginRight="2"
                      cursor="pointer"
                      onClick={() => setSelectedSession(session)}
                      backgroundColor={selectedSession.id === session.id ? "#FFEEEE" : "white"}
                      border={selectedSession.id !== session.id ? "1px solid #FF6666" : "none"}
                    >
                      <Grid templateColumns="70% 30%">
                        <GridItem>
                          <Text fontWeight="bold" fontSize={"16px"} color={"#FF3E3E"}>
                            {`${session.type} Session ${index + 1}`}
                          </Text>
                          <Flex alignItems="center">
                            <Text color={"gray"} fontSize={"14px"} mr={2}>
                              {session.status}
                            </Text>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                icon={<ChevronDownIcon />}
                                variant="outline"
                                size="sm"
                                aria-label="Options"
                                border='none'
                                isDisabled={appointmentData.is_closed}
                              />
                              <MenuList>
                                {!appointmentData.is_closed && (
                                  <>
                                    {session.status === "Scheduled" && (
                                      <>
                                        <MenuItem
                                          onClick={async () => {
                                            const newStatus = "Cancelled";
                                            setSelectedSession({
                                              ...selectedSession,
                                              status: newStatus,
                                            });

                                            await updateRdvSession({
                                              payload: {
                                                status: newStatus,
                                              },
                                            });

                                            toast.success(t("status_updated"), {
                                              position: "bottom-center",
                                              variant: "subtle",
                                              backgroundColor: "#FF6666",
                                              color: "white",
                                            });
                                          }}
                                        >
                                          {t("status__cancelled")}
                                        </MenuItem>
                                        <MenuItem
                                          onClick={async () => {
                                            const newStatus = "Occurred";
                                            setSelectedSession({
                                              ...selectedSession,
                                              status: newStatus,
                                            });

                                            await updateRdvSession({
                                              payload: {
                                                status: newStatus,
                                              },
                                            });

                                            toast.success(t("status_updated"), {
                                              position: "bottom-center",
                                              variant: "subtle",
                                              backgroundColor: "#FF6666",
                                              color: "white",
                                            });
                                          }}
                                        >
                                          {t("status__occurred")}
                                        </MenuItem>
                                        <MenuItem
                                          onClick={async () => {
                                            const newStatus = "Postponed";
                                            setSelectedSession({
                                              ...selectedSession,
                                              status: newStatus,
                                            });

                                            await updateRdvSession({
                                              payload: {
                                                status: newStatus,
                                              },
                                            });

                                            toast.success(t("status_updated"), {
                                              position: "bottom-center",
                                              variant: "subtle",
                                              backgroundColor: "#FF6666",
                                              color: "white",
                                            });
                                          }}
                                        >
                                          {t("status__postponed")}
                                        </MenuItem>
                                        <MenuItem
                                          onClick={async () => {
                                            const newStatus = "Late Cancel";
                                            setSelectedSession({
                                              ...selectedSession,
                                              status: newStatus,
                                            });

                                            await updateRdvSession({
                                              payload: {
                                                status: newStatus,
                                              },
                                            });

                                            toast.success(t("status_updated"), {
                                              position: "bottom-center",
                                              variant: "subtle",
                                              backgroundColor: "#FF6666",
                                              color: "white",
                                            });
                                          }}
                                        >
                                          {t("status__late_cancel")}
                                        </MenuItem>
                                      </>
                                    )}
                                    {session.status === "Postponed" && (
                                      <>
                                        <MenuItem
                                          onClick={async () => {
                                            const newStatus = "Cancelled";
                                            setSelectedSession({
                                              ...selectedSession,
                                              status: newStatus,
                                            });

                                            await updateRdvSession({
                                              payload: {
                                                status: newStatus,
                                              },
                                            });

                                            toast.success(t("status_updated"), {
                                              position: "bottom-center",
                                              variant: "subtle",
                                              backgroundColor: "#FF6666",
                                              color: "white",
                                            });
                                          }}
                                        >
                                          {t("status__cancelled")}
                                        </MenuItem>
                                        <MenuItem
                                          onClick={async () => {
                                            const newStatus = "Occurred";
                                            setSelectedSession({
                                              ...selectedSession,
                                              status: newStatus,
                                            });

                                            await updateRdvSession({
                                              payload: {
                                                status: newStatus,
                                              },
                                            });

                                            toast.success(t("status_updated"), {
                                              position: "bottom-center",
                                              variant: "subtle",
                                              backgroundColor: "#FF6666",
                                              color: "white",
                                            });
                                          }}
                                        >
                                          {t("status__occurred")}
                                        </MenuItem>
                                      </>
                                    )}
                                    {session.status === "Cancelled" && (
                                      <>
                                        <MenuItem isDisabled>{t("status__cancelled")}</MenuItem>
                                      </>
                                    )}
                                    {session.status === "Late Cancel" && (
                                      <>
                                        <MenuItem isDisabled>{t("status__late_cancel")}</MenuItem>
                                      </>
                                    )}
                                    {session.status === "Occurred" && (
                                      <>
                                        <MenuItem isDisabled>{t("status__occurred")}</MenuItem>
                                      </>
                                    )}
                                  </>
                                )}
                              </MenuList>
                            </Menu>
                          </Flex>
                        </GridItem>
                        <GridItem>
                          {session.status !== "Cancelled" && !appointmentData.is_closed && (
                            <MdDelete
                              size={20}
                              color={"#FF6666"}
                              onClick={() => {
                                openDialog(
                                  t("delete_session"),
                                  t("delete_session_confirmation"),
                                  async () => {
                                    await rdvSessionService.softDelete({
                                      pk: session.id,
                                      accessToken,
                                    });
                                    window.location.reload();
                                  },
                                  () => {
                                    console.log("Closed without confirming.");
                                  }
                                );
                              }}
                            />
                          )}
                          {session.status === "Cancelled" && (
                            <MdCancel size={20} color={"#FF6666"} />
                          )}
                          {session.status === "Late Cancel" && (
                            <MdCancel size={20} color={"#FF6666"} />
                          )}
                        </GridItem>
                      </Grid>
                    </Box>
                  ))}
                  {appointmentData.rdv_sessions.length < 10 && (
                    <Box sx={{ height: "100%" }}>
                      <Button
                        fontSize={"18px"}
                        fontWeight={"300"}
                        mx={"auto"}
                        color={"#FF6666"}
                        bgColor={"#FFEEEE"}
                        padding={"8px 16px"}
                        borderRadius={"4px"}
                        isDisabled={appointmentData.is_closed}
                        mt={4}
                        onClick={() => setIsFirstSessionDialogOpen(true)}
                      >
                        {t("ADD_NEW_SESSION")}
                      </Button>
                    </Box>
                  )}
                </>
              </Flex>
            </Box>
          </GridItem>
          <Box height="1px" width="100%" backgroundColor="#E7E7E7" marginY="2"></Box>
          <GridItem>
            <Tabs onChange={(index) => {
              switch (index) {
                case 0:
                  setActiveTab("sessionDetails");
                  break;
                case 1:
                  setActiveTab("documents");
                  break;
                case 2:
                  setActiveTab("regime");
                  break;
                default:
                  break;
              }
            }}>
              <TabList>
                <Tab ml={5} sx={activeTab === "sessionDetails" ? activeTabStyle : inactiveTabStyle}>
                  {t("session_details")}
                </Tab>
                <Tab sx={activeTab === "documents" ? activeTabStyle : inactiveTabStyle}>
                  {t("notes")}
                </Tab>
                {/*<Tab ml={5} sx={activeTab === "regime" ? activeTabStyle : inactiveTabStyle}>
                  Regime
                </Tab>*/}
                <Tab ml={5} sx={activeTab === "documents" ? activeTabStyle : inactiveTabStyle}>
                  {t("documents")}
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Box overflowY="scroll" padding="4" height={"80vh"}>
                    <Grid templateColumns="1fr 1fr" gap={4}>
                      <GridItem>
                        <Flex direction={"column"} width={"100%"} paddingBottom={5}>
                          <FormControl ml={4} marginY={5} width={"100%"} isDisabled={appointmentData.is_closed}>
                            <FormLabel display={"flex"}>
                              {t("admin__appointments__status")}
                              <Text ml={1} color={"#EE0000"}>*</Text>
                            </FormLabel>
                            <Text>{new Date(selectedSession.date) < new Date()}</Text>
                            <Select
                              id="status"
                              name="status"
                              className="form-control"
                              isRequired={true}
                              fontSize={"14px"}
                              fontWeight={"400"}
                              borderRadius="4px"
                              isDisabled={appointmentData.is_closed || selectedSession.status !== "Scheduled" || !(new Date(selectedSession.date) < new Date())}
                              value={selectedSession.status}
                              mb={5}
                              onChange={async (e) => {
                                setSelectedSession({
                                  ...selectedSession,
                                  status: e.target.value,
                                });
                                await updateRdvSession({
                                  payload: {
                                    status: e.target.value,
                                  },
                                });
                                toast.success(t("status_updated"), {
                                  position: "bottom-center",
                                  variant: "subtle",
                                  backgroundColor: "#FF6666",
                                  color: "white",
                                });
                              }}
                            >
                              <option value={t("status__occurred")}>{t("status__occurred")}</option>
                              <option value="Scheduled">{t("status__scheduled")}</option>
                              <option value="No Show">{t("status__no_show")}</option>
                              <option value="Late Cancel">{t("status__late_cancel")}</option>
                            </Select>
                            <FormControl mt={4} isDisabled={appointmentData.is_closed}>
                              <FormLabel display={"flex"}>
                                {t("session_type")}
                                <Text ml={1} color={"#EE0000"}>*</Text>
                              </FormLabel>
                              <Select
                                id="status"
                                name="status"
                                className="form-control"
                                isRequired={true}
                                fontSize={"14px"}
                                fontWeight={"400"}
                                borderRadius="4px"
                                mb={5}
                                isDisabled={true}
                              >
                                <option value="Info">{t("info")}</option>
                                <option value="Mediation">{t("mediation")}</option>
                              </Select>
                            </FormControl>
                            <FormControl mt={4} isDisabled={appointmentData.is_closed}>
                              <FormLabel display={"flex"}>
                                {t("location")}
                                <Text ml={1} color={"#EE0000"}>*</Text>
                              </FormLabel>
                              <Select
                                id="location"
                                name="location"
                                className="form-control"
                                isRequired={true}
                                fontSize={"14px"}
                                fontWeight={"400"}
                                borderRadius="4px"
                                mb={5}
                                onChange={(e) => {
                                  setSelectedLocation(e.target.value);
                                }}
                                value={selectedSession.location ?? appointmentData.location}
                                isDisabled={selectedSession.location !== null || appointmentData.is_closed || selectedSession.status !== "Scheduled"}
                              >
                                {availableLocations.map((location) => (
                                  <option key={location.id} value={location.id}>
                                    {location.name}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>


                            <FormControl mt={4} isDisabled={appointmentData.is_closed}>
                                <FormLabel display={"flex"}>
                                  {t("mediator")}
                                  <Text ml={1} color={"#EE0000"}>*</Text>
                                </FormLabel>
                                <Select
                                  id="mediator"
                                  name="mediator"
                                  className="form-control"
                                  isRequired={true}
                                  fontSize={"14px"}
                                  fontWeight={"400"}
                                  borderRadius="4px"
                                  mb={5}
                                  value={selectedSession.service_provider ? selectedSession.service_provider.id : ""}
                                  isDisabled={true}
                                >
                                  <option value={selectedSession.service_provider ? selectedSession.service_provider.id : ""}>
                                    {selectedSession.service_provider
                                      ? `${selectedSession.service_provider.last_name} ${selectedSession.service_provider.first_name}`
                                      : t("select_mediator")}
                                  </option>
                                </Select>
                              </FormControl>

                              <FormControl mt={4} isDisabled={appointmentData.is_closed}>
                                <FormLabel display={"flex"}>
                                  {t("time_slot")}
                                  <Text ml={1} color={"#EE0000"}>*</Text>
                                </FormLabel>
                                <Select
                                  id="timeSlot"
                                  name="timeSlot"
                                  className="form-control"
                                  isRequired={true}
                                  fontSize={"14px"}
                                  fontWeight={"400"}
                                  borderRadius="4px"
                                  mb={5}
                                  value={selectedSession.calendar_block ? selectedSession.calendar_block.id : ""}
                                  isDisabled={true}
                                >
                                  <option value={selectedSession.calendar_block ? selectedSession.calendar_block.id : ""}>
                                    {selectedSession.calendar_block
                                      ? `${format(new Date(selectedSession.calendar_block.start_date), 'yyyy-MM-dd HH:mm')} - ${format(new Date(selectedSession.calendar_block.end_date), 'yyyy-MM-dd HH:mm')}`
                                      : t("select_time_slot")}
                                  </option>
                                </Select>
                              </FormControl>
                          </FormControl>
                        </Flex>
                      </GridItem>

                      <GridItem>
                        <VStack spacing={4} marginY={5} ml={10} align={"start"}>
                          <Divider />
                          <Button
                            fontSize={"16px"}
                            fontWeight={"300"}
                            mx={"auto"}
                            color={"#FF6666"}
                            bgColor={"#FFEEEE"}
                            padding={"8px 16px"}
                            borderRadius={"4px"}
                            isDisabled={participantsForRDV.length === 0 || !selectedLocation || appointmentData.is_closed}
                            onClick={() =>
                              openDialog(
                                t("confirm_invitation"),
                                t("confirm_invitation__body"),
                                () => {
                                  console.log("Action Confirmed!");
                                  triggerPromiseBasedToast();
                                },
                                () => {
                                  console.log("Closed without confirming.");
                                }
                              )
                            }
                            mt={4}
                          >
                            {t("invite_participants")}
                          </Button>
                          <Text color={"#FF6666"} fontSize={"14px"}>
                            {(selectedLocation && participantsForRDV.length !== 0) ? "" : t("select_appropriate_location_and_participants")}
                          </Text>
                          <Divider />
                          <MultiSelectCheckbox
                            checkBoxItems={appointmentData.participants}
                            parentName={
                              selectedSession.participants.length > 0
                                ? t("invited_participants")
                                : t("all_participants")
                            }
                            preSelectedIds={selectedSession.participants}
                            disableEdit={selectedSession.participants.length > 0 || appointmentData.is_closed || selectedSession.status !== "Scheduled"}
                            liftStateUp={(checkedItems) => {
                              setParticipantsForRDV(checkedItems);
                              console.log(checkedItems);
                            }}
                          />
                        </VStack>
                      </GridItem>
                    </Grid>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box height={"80vh"}>
                    <Center>
                      <FormControl mt={4}>
                        <FormLabel>{t("service_provider_notes")}</FormLabel>
                        <TrixEditor
                          value={selectedSession.note}
                          onChange={(value) =>
                            updateRdvSession({ payload: { note: value } })
                          }
                        />
                        <Text color="grey.500">{t("saved_auto")}</Text>
                      </FormControl>
                    </Center>
                  </Box>
                </TabPanel>
               { /*<TabPanel>
                  <Flex direction={"column"} width={"100%"} paddingBottom={5}>
                    <FormControl ml={4} marginY={5} width={"100%"} isDisabled={appointmentData.is_closed}>
                      <FormLabel display={"flex"}>
                        regime
                        <Text ml={1} color={"#EE0000"}>*</Text>
                      </FormLabel>
                      <Select placeholder="select regime">
                        <option value='option1'>Option 1</option>
                        <option value='option2'>Option 2</option>
                        <option value='option3'>Option 3</option>
                      </Select>
                      <FormLabel display={"flex"} mt={4}>
                        Amount
                      </FormLabel>
                      <Input placeholder="EURO" />
                    </FormControl>
                  </Flex>
                </TabPanel>*/}
                <TabPanel>
                  <DocumentsTab sessionId={selectedSession.id} accessToken={accessToken} documents={selectedSession.documents} liftStateUp={() => onStateChange()}/>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </GridItem>
        </Grid>
      )}
      <CreateFirstSessionDialog
        isOpen={isFirstSessionDialogOpen}
        onClose={() => setIsFirstSessionDialogOpen(false)}
        accessToken={accessToken}
        originalRdvId={appointmentData.id}
        allMediators={collaboratorsData}
        liftStateUp={() => onStateChange()}
        availableLocations={availableLocations}
        preferredLocation={selectedLocation}
        proposedDate={appointmentData.date}
        proposedTime={appointmentData.time}
        serviceId={appointmentData.service.id}
        isFirstSession={appointmentData.rdv_sessions.length === 0}
      />
      <GenericAlertDialog
        isOpen={isOpen}
        title={alertDialogTitle}
        body={alertDialogBody}
        onClose={handleClose}
        confirm={handleConfirm}
      />
      <ToastContainer />
    </Box>
  );
};

export default function Sessions({
  collaboratorsData,
  appointmentData,
  accessToken,
  onStateChange,
}) {
  return (
    <CSessions
      collaboratorsData={collaboratorsData}
      appointmentData={appointmentData}
      accessToken={accessToken}
      onStateChange={onStateChange}
    />
  );
}
