import React, { useEffect, useRef } from "react";
import "trix/dist/trix.css";
import "trix";

const TrixEditor = ({ value, onChange }) => {
  const editorRef = useRef(null);

  useEffect(() => {
    const editor = editorRef.current;

    // Function to handle the change event
    const handleEditorChange = (event) => {
      const htmlContent = event.target.innerHTML;
      onChange(htmlContent);
    };

    // Add event listener for Trix editor changes
    editor.addEventListener("trix-change", handleEditorChange);

    // Clean up event listener on component unmount
    return () => {
      editor.removeEventListener("trix-change", handleEditorChange);
    };
  }, [onChange]);

  // Init the Trix editor with value if value is provided
  useEffect(() => {
    if (value) {
      const editor = editorRef.current;
      editor.editor.loadHTML(value);
    }
  }, [value]);

  return (
    <div>
      <input id="x" type="hidden" name="content" />
      <trix-editor input="x" ref={editorRef}></trix-editor>
    </div>
  );
};

export default TrixEditor;
