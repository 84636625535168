import React, { useState, Suspense } from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import DefaultAuth from "layouts/auth/Default";

import { useDispatch } from "react-redux";
import { loginSuccess, loginFailure } from "actions/authActions";
import authService from "services/authService";
import routes from "routes";
import AuthLayout from "layouts/auth";

// Language Management
import { useTranslation } from "react-i18next";
import LanguageSelector from "components/menu/LanguageSelector";

// Assets
import illustration from "assets/img/auth/auth.png";

import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";


function CSignIn() {


  // Init i18n
  const { t, i18n } = useTranslation();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const handleRememberMeChange = () => setRememberMe(!rememberMe);

  const dispatch = useDispatch();

  const handleLogin = async () => {
    try {
      const {
        accessToken,
        refreshToken,
        user,
        professional_profile,
        association,
      } = await authService.login(username, password);
      dispatch(
        loginSuccess({
          accessToken,
          refreshToken,
          user,
          professional_profile,
          association,
        })
      );
      // Redirect to next view upon successful login
      // Find the default admin view route
      const defaultAdminRoute = routes.find(
        (route) => route.layout === "/admin" && route.path === "/default"
      );

      // Redirect to the default admin view upon successful login
      history.push(`${defaultAdminRoute.layout}${defaultAdminRoute.path}`);
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  console.log("something happened here ");
  // Add this at the beginning of the component

  if (isAuthenticated) {
    return <Redirect to="/admin/default" />;
  }
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="start"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection="column"
        >
          <LanguageSelector />
        </Flex>
        <Box me="auto">
          {/* Language Selector */}
          <Heading
            color={"#252525"}
            fontSize="56px"
            mb="10px"
            fontWeight={"500"}
          >
            {/* Welcom Header */}
            {t("auth__signin__header")}
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color="#565656"
            fontWeight="400"
            fontSize="28px"
          >
            {t("auth__signin__description")}
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          {/* <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button> */}
          {/* <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex> */}
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="16px"
              fontWeight="300"
              color={textColor}
              mb="8px"
            >
              {t("common__email")}
              <Text color={"#EE0000"} ml={"1px"}>
                *
              </Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="username"
              placeholder="name.surname@gmail.com"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={handleUsernameChange}
              borderRadius="4px"
            />
            <FormLabel
              ms="4px"
              fontSize="16px"
              fontWeight="300"
              color={textColor}
              display="flex"
            >
              {t("common__password")}
              <Text color={"#EE0000"} ml={"1px"}>
                *
              </Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder={t("common__password__hint")}
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                onChange={handlePasswordChange}
                variant="auth"
                borderRadius={"4px"}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                  _hover={{ bg: "#FF8080" }}
                  sx={{
                    "& .chakra-checkbox__control": {
                      // bg: "#FF6666",
                      borderColor: "#FF6666",
                      _checked: {
                        bg: "#FF6666",
                        borderColor: "#FF6666",
                      },
                    },
                  }}
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="14px"
                >
                  {t("auth__signin__stay_loggedin")}
                </FormLabel>
              </FormControl>
              {/* <NavLink to="/auth/forgot-password">
                <Text
                  color={"#FF3E3E"}
                  fontSize="14px"
                  w="124px"
                  fontWeight="400"
                >
                  Forgot password?
                </Text>
              </NavLink> */}
            </Flex>
            <Button
              color={"white"}
              fontSize="20px"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              py={"7"}
              mb="24px"
              onClick={handleLogin}
              bgColor={"#FF6666"}
              borderRadius={0}
              _hover={{ bg: "#FF8080" }}
            >
              {t("auth__signin__btn_signin")}
            </Button>
          </FormControl>
          {/* <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to="/auth/sign-up">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex> */}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function SignInCentered() {
  return (
    <AuthLayout>
        <CSignIn />
    </AuthLayout>
  );
}
