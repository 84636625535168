import React, { useState, Suspense } from "react";
import {
  Text,
  Flex,
  Box,
  Button,
  Link,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/provider";

import RequestDetails from "./RequestDetails";
import Sessions from "./Session";

// Language Management
import { useTranslation } from "react-i18next";

// Dialogs
import CompleteRequest from "./CompleteRequest.dialog";
import RdvGeneralDocs from "./RdvGeneralDocs"

const activeTabStyle = {
  color: "#FF6666",
  fontSize: "18px",
  fontWeight: "600",
};

const inactiveTabStyle = {
  color: "#565656",
  fontSize: "18px",
  fontWeight: "600",
};

const CAppointmentDetails = ({
  data,
  collaboratorsData,
  accessToken,
  onStateChange,
}) => {
  console.log(onStateChange)
  // Init i18n
  const { t } = useTranslation();

  // State Management
  const [active, setActive] = useState("details");

  // Complete Request State
  const [sessionClosureDialogOpen, setSessionClosureDialogOpen] =
    useState(false);

  return (
    <Box overflow="hidden" maxWidth="100%" padding={4}>
      <Flex direction={"column"}>
        <Flex justify={"space-between"} alignItems={"center"} marginX={4} width={'100%'}>
          <Box color={"#252525"} fontSize={"22px"} fontWeight={"500"} ml={3}>
            {t("admin__appointments__req_file")} &lt;
            {data.file_number}&gt;
          </Box>
          <Flex>
            {/* Dont show the button if the mediator is null */}
            {data.mediator && !data.is_closed && (
              <Button
                fontSize={"16px"}
                color={"#FF6666"}
                backgroundColor={"#FFEEEE"}
                padding={1}
                onClick={() => setSessionClosureDialogOpen(true)}
              >
                {t("admin__appointments__complete_req")}
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex
          height={1}
          width={"100%"}
          borderBottom={"solid 1px #E7E7E7"}
        ></Flex>
        <Tabs
          onChange={(index) => setActive(index === 0 ? "details" : "sessions")}
        >
          <TabList>
            <Tab
              ml={5}
              sx={active === "details" ? activeTabStyle : inactiveTabStyle}
            >
              {t("admin__appointments__req_details")}
            </Tab>
            <Tab sx={active === "sessions" ? activeTabStyle : inactiveTabStyle}>
              {t("admin__appointments__sessions")} ({data.rdv_sessions.length})
            </Tab>

            <Tab sx={active === "sessions" ? activeTabStyle : inactiveTabStyle}>
              Rdv Documents
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <RequestDetails
                data={data}
                collaboratorsData={collaboratorsData}
                accessToken={accessToken}
                refresh={onStateChange}
              />
            </TabPanel>
            <TabPanel>
              <Sessions
                collaboratorsData={collaboratorsData}
                appointmentData={data}
                accessToken={accessToken}
                onStateChange={onStateChange}
              />
            </TabPanel>
            <TabPanel>
              <RdvGeneralDocs
                documents={data.documents}
                accessToken={accessToken}
                appointmentData={data}
                onStateChange={onStateChange}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <CompleteRequest
        isOpen={sessionClosureDialogOpen}
        onClose={() => setSessionClosureDialogOpen(false)}
        accessToken={accessToken}
        originalRdvId={data.id}
      />
    </Box>
  );
};

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function AppointmentDetails({
  data,
  collaboratorsData,
  accessToken,
  onStateChange,
}) {
  return (
      <CAppointmentDetails
        data={data}
        collaboratorsData={collaboratorsData}
        accessToken={accessToken}
        onStateChange={onStateChange}
      />
  );
}
