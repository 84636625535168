export const allEventsColumnsData = [
  {
    Header: "Participants",
    accessor: "participants",
  },
  {
    Header: "Service",
    accessor: "service",
  },
  {
    Header: "Location",
    accessor: "location",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Time",
    accessor: "time",
  },
];
