import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Input,
  IconButton,
  Text,
  Flex,
  Textarea,
} from "@chakra-ui/react";
import { FaEye, FaDownload, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import documentService from "services/documentService";
import rdvSessionService from "services/rdvSessionService";

const CustomModal = ({ isOpen, onClose, children }) => {
    const { t } = useTranslation();

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        maxWidth: '500px',
        width: '100%',
      }}>
        {children}
               <Button onClick={onClose} mt={4}>{t("close")}</Button>

      </div>
    </div>,
    document.body
  );
};

const DocumentsTab = ({ sessionId, accessToken, documents: initialDocuments, liftStateUp }) => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState(initialDocuments);
  const [newDocument, setNewDocument] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setDocuments(initialDocuments);
  }, [initialDocuments]);

  const handleFileChange = (event) => {
    setNewDocument(event.target.files[0]);
    setDisplayName(event.target.files[0]?.name || "");
  };

  const handleUpload = async () => {
    if (newDocument) {
      try {
        const res = await documentService.startUpload(newDocument.name, newDocument.size, newDocument.type, displayName, accessToken);
        const url = res.url;
        const idfile = res.id;

        await documentService.uploadToS3(url, newDocument);
        await documentService.finishUpload(idfile, accessToken);
        await rdvSessionService.update({
          pk: sessionId,
          accessToken: accessToken,
          payload: {
            documents: [...documents.map(doc => doc.id), idfile]
          }
        });

        toast.success(t("document_uploaded"));
        window.location.reload();
      } catch (error) {
        toast.error(t("document_upload_failed"));
        console.error(error);
      }
    }
  };

  const handlePreview = (document) => {
    window.open(document.file, '_blank');
  };

  const handleDownload = (document) => {
    const link = document.createElement('a');
    link.href = document.file;
    link.download = document.original_file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEditNote = (document) => {
    setSelectedDocument(document);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedDocument(null);
  };

  const handleSaveNote = async () => {
    try {
      await documentService.updateNote(selectedDocument.id, selectedDocument.note, accessToken);
      toast.success(t("note_saved"));
      handleCloseModal();
    } catch (error) {
      toast.error(t("note_save_failed"));
      console.error(error);
    }
  };

  return (
    <Box>
      <Flex alignItems="center" mb={4}>
        <Input type="file" onChange={handleFileChange} mr={2} />
        <Input
          placeholder={t("display_name")}
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          mr={2}
        />
      </Flex>

      <Button colorScheme="blue" onClick={handleUpload}>{t("upload_document")}</Button>

      <Text mt={4} mb={4}>{t("existing_documents")}: {documents.length}</Text>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>{t("document_name")}</Th>
              <Th>{t("document_size")}</Th>
              <Th>{t("document_type")}</Th>
              <Th>{t("actions")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {documents.length > 0 ? (
              documents.map((document) => (
                <Tr key={document.id}>
                  <Td>{document.original_file_name}</Td>
                  <Td>{(document.size / 1024).toFixed(2)} KB</Td>
                  <Td>{document.file_type}</Td>
                  <Td>
                    <IconButton
                      icon={<FaEye />}
                      aria-label={t("preview")}
                      mr={2}
                      onClick={() => handlePreview(document)}
                    />
                    <IconButton
                      icon={<FaDownload />}
                      aria-label={t("download")}
                      mr={2}
                      onClick={() => handleDownload(document)}
                    />
                    <IconButton
                      icon={<FaEdit />}
                      aria-label={t("edit_note")}
                      onClick={() => handleEditNote(document)}
                    />
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="4" textAlign="center">
                  {t("no_documents_found")}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <Text fontWeight="bold" mb={4}>{t("edit_note")}</Text>
        <Textarea
          value={selectedDocument?.note || ""}
          onChange={(e) => setSelectedDocument({...selectedDocument, note: e.target.value})}
          placeholder={t("enter_note")}
          mb={4}
        />
        <Button colorScheme="blue" mr={3} onClick={handleSaveNote}>
          {t("save_note")}
        </Button>
      </CustomModal>
    </Box>
  );
};

export default DocumentsTab;
