// SearchAndFilter.js
import React from "react";
import { Flex, Input, Button, Select, Box } from "@chakra-ui/react";
import { SearchIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import FilterIcon from "../../../../assets/img/dashboards/filter_icon.svg";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

const selectStyles = {
  backgroundColor: "rgba(86, 86, 86, 15%)",
  borderRadius: "12px",
  color: "gray.900",
  marginLeft: "10px",
};

const datePickerStyles = {
  ...selectStyles,
  padding: "8px 12px",
  border: "none",
  width: "150px",
  marginRight: "10px",
  _placeholder: { color: "black", fontSize: "" },
};

const SearchAndFilter = ({
  searchType,
  setSearchType,
  searchQuery,
  setSearchQuery,
  handleSearch,
  filterQuery,
  setFilterQuery,
  handleFilter,
  locationsList,
  handleResetSearchAndFilter,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}) => {
  const { t } = useTranslation();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <Box
        marginY={"10px"}
        width={"100%"}
        border="1px"
        borderColor="gray.300"
        borderRight={"none"}
        paddingY={2}
      >
        <Flex>
          <SearchIcon fontSize={"2xl"} marginX={4} />
          <Select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            width="150px"
            marginRight={4}
          >
            <option value="participant_name">{t("admin_filters.names")}</option>
            <option value="file_number">
              {t("admin_filters.file_numbers")}
            </option>
            <option value="documents">{t("admin_filters.documents")}</option>
            <option value="participant_email">
              {t("admin_filters.emails")}
            </option>
          </Select>
          <Input
            placeholder={t("admin_filters.search")}
            style={{ width: "100%" }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Button onClick={handleSearch} marginLeft={4}>
            {t("admin_filters.search")}
          </Button>
        </Flex>
      </Box>
      <Box
        width={"100%"}
        border="1px"
        borderColor="gray.300"
        borderRight={"none"}
        paddingY={2}
        borderTop={"none"}
      >
        <Flex alignItems="center">
          <Box marginLeft={4}>
            <img
              style={{ width: "30px", height: "30px" }}
              src={FilterIcon}
              alt="filter"
            />
          </Box>
          <Select
            placeholder={t("placeholder.status")}
            sx={selectStyles}
            width={"10%"}
            marginRight={4}
            value={filterQuery.status}
            onChange={(e) =>
              setFilterQuery({ ...filterQuery, status: e.target.value })
            }
          >
            <option value="Scheduled">{t("admin_status.scheduled")}</option>
            <option value="Completed">{t("admin_status.completed")}</option>
            <option value="In Progress">{t("admin_status.in_progress")}</option>
          </Select>
          <Select
            placeholder={t("placeholder.location")}
            sx={selectStyles}
            width={"10%"}
            marginRight={4}
            value={filterQuery.location}
            onChange={(e) =>
              setFilterQuery({ ...filterQuery, location: e.target.value })
            }
          >
            {locationsList.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </Select>
          <DatePicker
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            selectsStart
            startDate={fromDate}
            endDate={toDate}
            placeholderText={t("admin_filters.from_date")}
            customInput={<Input sx={datePickerStyles} />}
            showPopperArrow={true}
          />
          <DatePicker
            selected={toDate}
            onChange={(date) => setToDate(date)}
            selectsEnd
            startDate={fromDate}
            endDate={toDate}
            placeholderText={t("admin_filters.to_date")}
            customInput={<Input sx={datePickerStyles} />}
            showPopperArrow={true}
          />
          <Button
            onClick={handleFilter}
            marginLeft={4}
            bg={"green.300"}
            color={"white"}
          >
            <CheckIcon />
          </Button>
          <Button
            onClick={handleResetSearchAndFilter}
            marginLeft={4}
            bg={"red.300"}
            color={"white"}
          >
            <CloseIcon />
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default SearchAndFilter;
