import React, { useState, useEffect, useReducer, useRef } from "react";
import {  Text,Flex,  Box,  Button,FormControl,  FormLabel,  Input,  Table,  Thead,  Tbody,  Tr,  Th,Td,  TableContainer,  Modal,ModalOverlay,  ModalContent,  ModalHeader,  ModalFooter,  ModalBody,  useDisclosure,  Select,  Checkbox,  Menu,  MenuButton,  MenuList,MenuItem,CircularProgress,Spinner,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { FaEllipsisV } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import rdvService from "services/rdvService";
import GenericAlertDialog from "./GenericAlertDialog";
import { useParams } from "react-router-dom";

const tableHeaderStyles = {
  textAlign: "start",
  fontSize: "16px",
  fontWeight: "500",
  color: "#252525",
};

const tableContentStyles = {
  fontSize: "16px",
  fontWeight: "400",
  color: "#565656",
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^(?:\+?[1-9]\d{1,14}|\d{10})$/;
const postalCodeRegex = /^\d{5}$/; // Basic 5-digit postal code
const amountRegex = /^\d+(\.\d{1,2})?$/; // Allows two decimal places

const validateEmail = (email) => emailRegex.test(email);
const validatePhone = (phone) => phoneRegex.test(phone);
const validatePostalCode = (postalCode) => postalCodeRegex.test(postalCode);
const validateAmount = (amount) => amountRegex.test(amount);
const validateChildEmail = (email) => {
  if (email === "") return true;
  return emailRegex.test(email);
};

const CRequestDetails = ({ data, collaboratorsData, accessToken, refresh }) => {
  const { t } = useTranslation();
  const {
    isOpen: isAlertDialogOpen,
    onOpen: onAlertDialogOpen,
    onClose: onAlertDialogClose,
  } = useDisclosure();

  const initialParticipantRef = useRef(null);
  const finalParticipantRef = useRef(null);
  const initialChildrenRef = useRef(null);
  const finalChildrenRef = useRef(null);

  const [alertDialogTitle, setAlertDialogTitle] = useState("");
  const [alertDialogBody, setAlertDialogBody] = useState("");
  const [alertDialogOnClose, setAlertDialogOnClose] = useState(() => () => {});
  const [alertDialogConfirm, setAlertDialogConfirm] = useState(() => () => {});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [participantForm, setParticipantForm] = useState({
    name: "",
    relation: "",
    address: "",
    cp: "",
    phone: "",
    email: "",
    legal_aid: "",
    regime: "",
    detail: "",
    amount: 0,
  });

  const { appointmentId } = useParams();
  const [currentParticipantId, setCurrentParticipantId] = useState(null);
  const [addingParticipant, setAddingParticipant] = useState(false);
  const [legalMode, setLegalMode] = useState(data.nature);
  const [contextJurid, setContextJurid] = useState(data.legal_context);
  const [subject, setSubject] = useState(data.subject);
  const [childrenForm, setChildrenForm] = useState({
    name: "",
    date_of_birth: "",
    email: "",
    gender: "",
    adopted: "No",
  });
  const [currentChildId, setCurrentChildId] = useState(null);
  const [addingChildren, setAddingChildren] = useState(false);

  const {
    isOpen: isParticipantModalOpen,
    onOpen: onParticipantModalOpen,
    onClose: onParticipantModalClose,
  } = useDisclosure();

  const {
    isOpen: isChildrenModalOpen,
    onOpen: onChildrenModalOpen,
    onClose: onChildrenModalClose,
  } = useDisclosure();

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (!isParticipantModalOpen && !isChildrenModalOpen) {
      console.log(
        "Modal closed, checking for overlays:",
        document.querySelector(".chakra-modal__overlay")
      );
    }
  }, [isParticipantModalOpen, isChildrenModalOpen]);

  useEffect(() => {
    return () => {
      const overlay = document.querySelector(".chakra-modal__overlay");
      if (overlay) {
        overlay.remove();
      }
    };
  }, []);

  const openGenericAlertDialog = (title, body, confirm, close) => {
    setAlertDialogTitle(title);
    setAlertDialogBody(body);
    setAlertDialogConfirm(() => confirm);
    setAlertDialogOnClose(() => close);
    onAlertDialogOpen();
  };

  const handleConfirm = () => {
    alertDialogConfirm();
    onAlertDialogClose();
  };

  const handleClose = () => {
    alertDialogOnClose();
    onAlertDialogClose();
  };

  const validateParticipantForm = () => {
    const newErrors = {};
    if (!participantForm.name) {
      newErrors.name = t('errors.nameRequired');
    }
    if (!participantForm.relation) {
      newErrors.relation = t('errors.relationRequired');
    }
    if (!participantForm.address) {
      newErrors.address = t('errors.addressRequired');
    }
    if (!validateEmail(participantForm.email)) {
      newErrors.email = t('errors.invalidEmailFormat');
    }
    if (!validatePhone(participantForm.phone)) {
      newErrors.phone = t('errors.invalidPhoneNumber');
    }
    if (!validatePostalCode(participantForm.cp)) {
      newErrors.cp = t('errors.invalidPostalCode');
    }
    if (!validateAmount(participantForm.amount)) {
      newErrors.amount = t('errors.invalidAmount');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

const handleAddParticipant = async () => {
  if (!validateParticipantForm()) return;

  setAddingParticipant(true);
  try {
    let res = await rdvService.addParticipant({
      pk: data.id,
      participant: participantForm,
      accessToken: accessToken,
    });
    toast(t('messages.changesSaved'), {
      type: "success",
      autoClose: 3000,
    });
    onParticipantModalClose();
    refresh();
  } catch (error) {
    toast(t('messages.failedToAddParticipant'), {
      type: "error",
      autoClose: 3000,
    });
    setIsLoading(false);
  } finally {
    setAddingParticipant(false);
  }
};

const handleEditParticipant = (participant) => {
  setParticipantForm(participant);
  setCurrentParticipantId(participant.id);
  onParticipantModalOpen();
};

const handleUpdateParticipant = async () => {
  if (!validateParticipantForm()) return;

  setAddingParticipant(true);
  try {
    await rdvService.updateParticipantInfo({
      rdvId: data.id,
      participantId: currentParticipantId,
      obj: participantForm,
      accessToken: accessToken,
    });
    toast(t('messages.changesSaved'), {
      type: "success",
    });
    onParticipantModalClose();
    refresh();
  } catch (error) {
    toast(t('messages.failedToUpdateParticipant'), {
      type: "error",
    });
    setIsLoading(false);
  } finally {
    setAddingParticipant(false);
  }
};

const handleRemoveParticipant = (participantId, participantName) => {
  openGenericAlertDialog(
    t('messages.removeParticipantConfirmation', { participantName }),
    t('messages.removeParticipantWarning', { participantName }),
    async () => {
      setIsLoading(true);
      try {
        const participantIds = data.participants.map(
          (participant) => participant.id
        );
        const updatedParticipants = participantIds.filter(
          (id) => id !== participantId
        );

        await rdvService.deleteParticipant({
          pk: data.id,
          updatedParticipants: {
            participants: updatedParticipants,
          },
          accessToken: accessToken,
        });

        toast(t('messages.changesSaved'), {
          type: "success",
          autoClose: 3000,
        });
        refresh();
      } catch (error) {
        toast(t('messages.failedToRemoveParticipant'), {
          type: "error",
          autoClose: 3000,
        });
      } finally {
        setIsLoading(false);
      }
    },
    handleClose
  );
};

const validateChildForm = () => {
  const newErrors = {};
  if (!childrenForm.name) {
    newErrors.name = t('errors.nameRequired');
  }
  if (!childrenForm.date_of_birth) {
    newErrors.date_of_birth = t('errors.dateOfBirthRequired');
  }
  if (!childrenForm.gender) {
    newErrors.gender = t('errors.genderRequired');
  }
  if (!validateChildEmail(childrenForm.email)) {
    newErrors.email = t('errors.invalidEmailFormat');
  }
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

const handleAddChild = async () => {
  if (!validateChildForm()) return;

  setAddingChildren(true);
  try {
    await rdvService.addChild({
      pk: data.id,
      child: childrenForm,
      accessToken: accessToken,
    });
    toast(t('messages.changesSaved'), {
      type: "success",
      duration: 3000,
    });
    onChildrenModalClose();
    refresh();
  } catch (error) {
    toast(t('messages.failedToAddChild'), {
      type: "error",
    });
    setIsLoading(false);
  } finally {
    setAddingChildren(false);
    setChildrenForm({
      name: "",
      date_of_birth: "",
      email: "",
      gender: "",
      adopted: "No",
    });
  }
};

const handleEditChild = (child) => {
  setChildrenForm(child);
  setCurrentChildId(child.id);
  onChildrenModalOpen();
};

const handleUpdateChild = async () => {
  if (!validateChildForm()) return;

  setAddingChildren(true);
  try {
    await rdvService.updateChildInfo({
      rdvId: data.id,
      childId: currentChildId,
      obj: childrenForm,
      accessToken: accessToken,
    });
    toast(t('messages.changesSaved'), {
      type: "success",
    });
    onChildrenModalClose();
    refresh();
  } catch (error) {
    toast(t('messages.failedToUpdateChild'), {
      type: "error",
    });
    setIsLoading(false);
  } finally {
    setAddingChildren(false);
    setChildrenForm({
      name: "",
      date_of_birth: "",
      email: "",
      gender: "",
      adopted: "No",
    });
  }
};

const handleRemoveChild = (childId, childName) => {
  openGenericAlertDialog(
    t('messages.removeChildConfirmation', { childName }),
    t('messages.removeChildWarning', { childName }),
    async () => {
      const childrenIds = data.children.map((child) => child.id);
      const updatedChildren = childrenIds.filter((id) => id !== childId);
      setIsLoading(true);

      await rdvService.update({
        pk: data.id,
        obj: {
          children: updatedChildren,
        },
        accessToken: accessToken,
      });

      toast(t('messages.changesSaved'), {
        type: "success",
      });
      refresh();
      setIsLoading(false);
    },
    () => {
      console.log("Closed without confirming.");
    }
  );
};


  return (
    <Box height="100%" overflowY="auto" padding={4}>
      <ToastContainer />
      <Button ref={finalParticipantRef} hidden>
        Focus anchor for participant modal
      </Button>
      <Button ref={finalChildrenRef} hidden>
        Focus anchor for children modal
      </Button>
      <Flex direction={"column"} width={"100%"} paddingBottom={5}>
        <FormControl
          ml={4}
          marginY={5}
          width={"100%"}
          isDisabled={data.is_closed}
        >
          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments__status")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Box ml={1} mb={5}>
            <AppointmentStatusLabel {...data} />
          </Box>
          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__file_number")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            width={"100%"}
            type="title"
            placeholder="2445524"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={data.file_number}
            contentEditable={false}
            disabled={true}
          />
          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__location")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            width={"100%"}
            type="title"
            placeholder="Location"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={data.location.name}
            disabled={true}
          />
          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__address")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            width={"100%"}
            type="title"
            placeholder="Location"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={data.location.address}
            disabled={true}
          />
          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__service")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            width={"100%"}
            type="title"
            placeholder="Mediation"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            value={data.service.name}
            mb={5}
            disabled={true}
          />
          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__proposed_date")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            width={"100%"}
            type="title"
            placeholder="14 April 2024"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={data.date}
            disabled={true}
          />
          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__proposed_time")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            width={"100%"}
            type="title"
            placeholder="11:30"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={data.time}
            disabled={true}
          />
          <Flex>
            <Box width="48%">
              <FormLabel
                fontSize={"16px"}
                color={"#252525"}
                fontWeight={"400"}
                display={"flex"}
              >
                {t("admin__appointments_table__legal_mode")}
                <Text ml={1} color={"#EE0000"}>
                  *
                </Text>
              </FormLabel>
              <Select
                isRequired={true}
                width={"100%"}
                placeholder="Select option"
                fontSize={"14px"}
                fontWeight={"400"}
                borderRadius="4px"
                mb={5}
                value={data.nature}
                onChange={(e) => {
                  setLegalMode(e.target.value);
                  const patchPayload = {
                    nature: e.target.value,
                  };
                  if (patchPayload.nature === "Conventionnel") {
                    patchPayload.legal_context = "None";
                  }
                  rdvService
                    .update({
                      pk: data.id,
                      accessToken: accessToken,
                      obj: patchPayload,
                    })
                    .then((res) => {
                      toast(t('messages.changesSaved'), {
                        type: "success",
                      });
                    });
                }}
              >
                <option value="Conventionnel">Conventionnel</option>
                <option value="Judiciaire">Judiciaire</option>
                <option value="TMFPO">TMFPO</option>
              </Select>
            </Box>
            <Box ml={5} width="48%">
              <FormLabel
                fontSize={"16px"}
                color={"#252525"}
                fontWeight={"400"}
                display={"flex"}
              >
                {t("admin__appointments_table__context_jurid")}
                <Text ml={1} color={"#EE0000"}>
                  *
                </Text>
              </FormLabel>
              <Select
                isRequired={true}
                width={"100%"}
                placeholder="Select option"
                fontSize={"14px"}
                fontWeight={"400"}
                borderRadius="4px"
                mb={5}
                disabled={data.nature === "Conventionnel"}
                value={data.legal_context}
                onChange={(e) => {
                  setContextJurid(e.target.value);
                  rdvService
                    .update({
                      pk: data.id,
                      accessToken: accessToken,
                      obj: {
                        legal_context: e.target.value,
                      },
                    })
                    .then((res) => {
                      toast(t('messages.changesSaved'), {
                        type: "success",
                      });
                    });
                }}
              >
                <option value="Double convocation">Double convocation</option>
                <option value="Injonction">Injonction</option>
                <option value="Ordonnance">Ordonnance</option>
                <option value="Cadre judiciaire sur invitation écrite de la Juridiction">
                  Cadre judiciaire sur invitation écrite de la Juridiction
                </option>
                <option value="TMFPO">TMFPO</option>
              </Select>
            </Box>
          </Flex>
          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__subject")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Select
            isRequired={true}
            width={"100%"}
            placeholder="Select option"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={data.subject}
            onChange={(e) => {
              setSubject(e.target.value);
              rdvService
                .update({
                  pk: data.id,
                  accessToken: accessToken,
                  obj: {
                    subject: e.target.value,
                  },
                })
                .then((res) => {
                  toast(t('messages.changesSaved'), {
                    type: "success",
                  });
                });
            }}
          >
            <option value={t("family_topic__decision_support")}>
              {t("family_topic__decision_support")}
            </option>
            <option value={t("family_topic__custody_arrangement_coparenting")}>
              {t("family_topic__custody_arrangement_coparenting")}
            </option>
            <option
              value={t("family_topic__education_maintenance_contribution")}
            >
              {t("family_topic__education_maintenance_contribution")}
            </option>
            <option value={t("family_topic__other_miscellaneous_situations")}>
              {t("family_topic__other_miscellaneous_situations")}
            </option>
            <option value={t("family_topic__same_sex_family")}>
              {t("family_topic__same_sex_family")}
            </option>
            <option value={t("family_topic__blended_family")}>
              {t("family_topic__blended_family")}
            </option>
            <option value={t("family_topic__siblings")}>
              {t("family_topic__siblings")}
            </option>
            <option
              value={t("family_topic__grandparents_children_grandchildren")}
            >
              {t("family_topic__grandparents_children_grandchildren")}
            </option>
            <option value={t("family_topic__parents_teenagers")}>
              {t("family_topic__parents_teenagers")}
            </option>
            <option value={t("family_topic__parents_young_adults")}>
              {t("family_topic__parents_young_adults")}
            </option>
            <option value={t("family_topic__separation_divorce")}>
              {t("family_topic__separation_divorce")}
            </option>
          </Select>
        </FormControl>
        <Flex
          direction={"column"}
          width={"100%"}
          margin={3}
          border="1px"
          borderColor="gray.300"
          borderRadius={"8px"}
        >
          <Flex
            justify={"space-between"}
            px={3}
            borderBottom={"1px"}
            borderColor={"#DADCE0"}
          >
            <Box fontSize={"24px"} fontWeight={"500"} marginY={"12px"}>
              {t("admin__appointments_table__participants")}
            </Box>
            <Button
              fontSize={"16px"}
              fontWeight={"500"}
              marginLeft={3}
              marginY={"auto"}
              color={"#FF6666"}
              onClick={onParticipantModalOpen}
              isDisabled={data.is_closed}
            >
              Add Participant
            </Button>
          </Flex>
          <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__name")}
                </Th>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__relation")}
                </Th>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__address")}
                </Th>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__telephone")}
                </Th>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__email")}
                </Th>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__regime")}
                </Th>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__amount")}
                </Th>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__more_actions")}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.participants.length === 0 ? (
                <Tr>
                  <Td colSpan={6} textAlign="center">
                    <Text fontSize="18px" color="gray.500">
                      No participants added yet.
                    </Text>
                  </Td>
                </Tr>
              ) : (
                data.participants.map((participant, index) => (
                  <Tr key={index}>
                    <Td sx={tableContentStyles}>
                      {participant.name ?? "N/A"}
                    </Td>
                    <Td sx={tableContentStyles}>
                      {participant.relation ?? "N/A"}
                    </Td>
                    <Td sx={tableContentStyles}>
                      {participant.address ?? "N/A"}
                    </Td>
                    <Td sx={tableContentStyles}>
                      {participant.phone ?? "N/A"}
                    </Td>
                    <Td sx={tableContentStyles}>
                      {participant.email ?? "N/A"}
                    </Td>
                    <Td sx={tableContentStyles}>
                      {participant.regime ?? "N/A"}
                    </Td>
                    <Td sx={tableContentStyles}>
                      {`${participant.amount}E` ?? "N/A"}
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton as={Button}>...</MenuButton>
                        <MenuList>
                          <MenuItem
                            icon={<EditIcon />}
                            onClick={() => handleEditParticipant(participant)}
                          >
                            {t("edit")}
                          </MenuItem>
                          <MenuItem
                            icon={<DeleteIcon />}
                            onClick={() =>
                              handleRemoveParticipant(
                                participant.id,
                                participant.name
                              )
                            }
                          >
                            {t("remove")}
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
        </Flex>
        <Flex
          direction={"column"}
          width={"100%"}
          margin={3}
          border="1px"
          borderColor="gray.300"
          borderRadius={"8px"}
        >
          <Flex
            justify={"space-between"}
            px={3}
            borderBottom={"1px"}
            borderColor={"#DADCE0"}
          >
            <Box fontSize={"24px"} fontWeight={"500"} marginY={"12px"}>
              {t("admin__appointments_table__children")}
            </Box>
            <Button
              fontSize={"16px"}
              fontWeight={"500"}
              marginLeft={3}
              marginY={"auto"}
              color={"#FF6666"}
              onClick={onChildrenModalOpen}
              isDisabled={data.is_closed}
            >
              Add child
            </Button>
          </Flex>
          <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__name")}
                </Th>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__dob")}
                </Th>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__gender")}
                </Th>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__adopted")}
                </Th>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__email")}
                </Th>
                <Th sx={tableHeaderStyles}>
                  {t("admin__appointments_table__more_actions")}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.children.length === 0 ? (
                <Tr>
                  <Td colSpan={6} textAlign="center">
                    <Text fontSize="18px" color="gray.500">
                      No children added yet.
                    </Text>
                  </Td>
                </Tr>
              ) : (
                data.children.map((child, index) => (
                  <Tr key={index}>
                    <Td sx={tableContentStyles}>{child.name ?? "N/A"}</Td>
                    <Td sx={tableContentStyles}>
                      {child.date_of_birth ?? "N/A"}
                    </Td>
                    <Td sx={tableContentStyles}>{child.gender ?? "N/A"}</Td>
                    <Td sx={tableContentStyles}>
                      {child.adopted ? "Yes" : "No"}
                    </Td>
                    <Td sx={tableContentStyles}>{child.email ?? "N/A"}</Td>
                    <Td>
                      <Menu>
                        <MenuButton as={Button}>...</MenuButton>
                        <MenuList>
                          <MenuItem
                            icon={<EditIcon />}
                            onClick={() => handleEditChild(child)}
                          >
                            {t("edit")}
                          </MenuItem>
                          <MenuItem
                            icon={<DeleteIcon />}
                            onClick={() =>
                              handleRemoveChild(child.id, child.name)
                            }
                          >
                            {t("remove")}
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
        </Flex>
      </Flex>

      {isParticipantModalOpen && (
        <Modal
          initialFocusRef={initialParticipantRef}
          finalFocusRef={finalParticipantRef}
          isOpen={isParticipantModalOpen}
          onClose={onParticipantModalClose}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontSize={"36px"}
              color={"#252525"}
              fontWeight={400}
              mx={"auto"}
            >
              {t("admin__appointments_table__participant_details")}
            </ModalHeader>
            <ModalBody>
              <FormControl isInvalid={errors.name}>
                <FormLabel display={"flex"}>
                  {t("admin__appointments_table__name")}{" "}
                  <Text ml={1} color={"#EE0000"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  ref={initialParticipantRef}
                  fontWeight={300}
                  placeholder="Name"
                  value={participantForm.name}
                  onChange={(e) =>
                    setParticipantForm({
                      ...participantForm,
                      name: e.target.value,
                    })
                  }
                />
                {errors.name && (
                  <Text color="crimson" mt={1}>
                    {errors.name}
                  </Text>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.relation}>
                <FormLabel display={"flex"}>
                  {t("admin__appointments_table__relation")}{" "}
                  <Text ml={1} color={"#EE0000"}>
                    *
                  </Text>
                </FormLabel>
                <Select
                  id="status"
                  name="status"
                  className="form-control"
                  isRequired={true}
                  placeholder="Select option"
                  fontSize={"14px"}
                  fontWeight={"400"}
                  borderRadius="4px"
                  value={participantForm.relation}
                  onChange={(e) => {
                    setParticipantForm({
                      ...participantForm,
                      relation: e.target.value,
                    });
                  }}
                  mb={5}
                >
                  <option value={t("family_relation__mother")}>
                    {t("family_relation__mother")}
                  </option>
                  <option value={t("family_relation__father")}>
                    {t("family_relation__father")}
                  </option>
                  <option value={t("family_relation__sister")}>
                    {t("family_relation__sister")}
                  </option>
                  <option value={t("family_relation__brother")}>
                    {t("family_relation__brother")}
                  </option>
                  <option value={t("family_relation__grandmother")}>
                    {t("family_relation__grandmother")}
                  </option>
                  <option value={t("family_relation__grandfather")}>
                    {t("family_relation__grandfather")}
                  </option>
                  <option value={t("family_relation__grandparent")}>
                    {t("family_relation__grandparent")}
                  </option>
                  <option value={t("family_relation__grandson")}>
                    {t("family_relation__grandson")}
                  </option>
                  <option value={t("family_relation__granddaughter")}>
                    {t("family_relation__granddaughter")}
                  </option>
                  <option value={t("family_relation__grandchild")}>
                    {t("family_relation__grandchild")}
                  </option>
                  <option value={t("family_relation__aunt")}>
                    {t("family_relation__aunt")}
                  </option>
                  <option value={t("family_relation__uncle")}>
                    {t("family_relation__uncle")}
                  </option>
                  <option value={t("family_relation__niece")}>
                    {t("family_relation__niece")}
                  </option>
                  <option value={t("family_relation__nephew")}>
                    {t("family_relation__nephew")}
                  </option>
                  <option value={t("family_relation__cousin")}>
                    {t("family_relation__cousin")}
                  </option>
                  <option value={t("family_relation__sister_in_law")}>
                    {t("family_relation__sister_in_law")}
                  </option>
                  <option value={t("family_relation__brother_in_law")}>
                    {t("family_relation__brother_in_law")}
                  </option>
                  <option value={t("family_relation__mother_in_law")}>
                    {t("family_relation__mother_in_law")}
                  </option>
                  <option value={t("family_relation__father_in_law")}>
                    {t("family_relation__father_in_law")}
                  </option>
                  <option value={t("family_relation__fiance")}>
                    {t("family_relation__fiance")}
                  </option>
                  <option value={t("family_relation__friend")}>
                    {t("family_relation__friend")}
                  </option>
                </Select>
                {errors.relation && (
                  <Text color="crimson" mt={1}>
                    {errors.relation}
                  </Text>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.address}>
                <FormLabel display={"flex"}>
                  {t("admin__appointments_table__address")}{" "}
                  <Text ml={1} color={"#EE0000"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  fontWeight={300}
                  placeholder="Address"
                  value={participantForm.address}
                  onChange={(e) =>
                    setParticipantForm({
                      ...participantForm,
                      address: e.target.value,
                    })
                  }
                />
                {errors.address && (
                  <Text color="crimson" mt={1}>
                    {errors.address}
                  </Text>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.cp}>
                <FormLabel display={"flex"}>
                  {t("admin__appointments_table__postal_code")}{" "}
                  <Text ml={1} color={"#EE0000"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  fontWeight={300}
                  placeholder="Postal Code"
                  value={participantForm.cp}
                  onChange={(e) =>
                    setParticipantForm({
                      ...participantForm,
                      cp: e.target.value,
                    })
                  }
                  errorBorderColor="crimson"
                />
                {errors.cp && (
                  <Text color="crimson" mt={1}>
                    {errors.cp}
                  </Text>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.phone}>
                <FormLabel display={"flex"}>
                  {t("admin__appointments_table__telephone")}{" "}
                  <Text ml={1} color={"#EE0000"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  fontWeight={300}
                  placeholder="Telephone"
                  value={participantForm.phone}
                  onChange={(e) =>
                    setParticipantForm({
                      ...participantForm,
                      phone: e.target.value,
                    })
                  }
                  errorBorderColor="crimson"
                />
                {errors.phone && (
                  <Text color="crimson" mt={1}>
                    {errors.phone}
                  </Text>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.email}>
                <FormLabel display={"flex"}>
                  {t("admin__appointments_table__email")}{" "}
                  <Text ml={1} color={"#EE0000"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  fontWeight={300}
                  placeholder="Email"
                  value={participantForm.email}
                  onChange={(e) =>
                    setParticipantForm({
                      ...participantForm,
                      email: e.target.value,
                    })
                  }
                  errorBorderColor="crimson"
                />
                {errors.email && (
                  <Text color="crimson" mt={1}>
                    {errors.email}
                  </Text>
                )}
              </FormControl>
              <FormControl mt={4}>
                <FormLabel display={"flex"}>
                  {t("admin__appointments_table__regime")}{" "}
                </FormLabel>
                <Select
                  id="regime"
                  name="regime"
                  className="form-control"
                  isRequired={true}
                  placeholder="Select option"
                  fontSize={"14px"}
                  fontWeight={"400"}
                  borderRadius="4px"
                  value={participantForm.regime}
                  onChange={(e) => {
                    setParticipantForm({
                      ...participantForm,
                      regime: e.target.value,
                    });
                  }}
                  mb={5}
                >
                  <option value="AUTRE">AUTRE</option>
                  <option value="CAF">CAF</option>
                  <option value="MSA">MSA</option>
                </Select>
              </FormControl>

              <FormControl mt={4} isInvalid={errors.amount}>
                <FormLabel display={"flex"}>
                  {t("admin__appointments_table__amount")}{" "}
                </FormLabel>
                <Input
                  fontWeight={300}
                  placeholder="Amount"
                  type="number"
                  value={participantForm.amount}
                  onChange={(e) =>
                    setParticipantForm({
                      ...participantForm,
                      amount: e.target.value,
                    })
                  }
                  errorBorderColor="crimson"
                />
                {errors.amount && (
                  <Text color="crimson" mt={1}>
                    {errors.amount}
                  </Text>
                )}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel display={"flex"}>
                  {t("admin__appointments_table__legal_aid")}
                </FormLabel>
                <Checkbox
                  colorScheme="red"
                  isChecked={participantForm.legal_aid}
                  onChange={(e) =>
                    setParticipantForm({
                      ...participantForm,
                      legal_aid: e.target.checked,
                    })
                  }
                >
                  {t("admin__appointments_table__legal_aid_value")}
                </Checkbox>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                fontSize={"18px"}
                mx={"auto"}
                color={"#FF6666"}
                bgColor={"#FFEEEE"}
                padding={"8px 16px"}
                borderRadius={"4px"}
                onClick={
                  currentParticipantId
                    ? handleUpdateParticipant
                    : handleAddParticipant
                }
                isDisabled={addingParticipant || !validateParticipantForm}
              >
                {addingParticipant ? (
                  <CircularProgress />
                ) : currentParticipantId ? (
                  t("admin__appointments_table__update_participant")
                ) : (
                  t("admin__appointments_table__add_participant")
                )}
              </Button>
              <Button
                fontSize={"18px"}
                mx={"auto"}
                color={"#FF6666"}
                bgColor={"#FFEEEE"}
                padding={"8px 16px"}
                borderRadius={"4px"}
                onClick={onParticipantModalClose}
              >
                {t("cancel")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {isChildrenModalOpen && (
        <Modal
          initialFocusRef={initialChildrenRef}
          finalFocusRef={finalChildrenRef}
          isOpen={isChildrenModalOpen}
          onClose={onChildrenModalClose}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontSize={"36px"}
              color={"#252525"}
              fontWeight={400}
              mx={"auto"}
            >
              {t("admin__appointments_table__child_details")}
            </ModalHeader>
            <ModalBody>
              <FormControl isInvalid={errors.name}>
                <FormLabel display={"flex"}>
                  Name{" "}
                  <Text ml={1} color={"#EE0000"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  isRequired="true"
                  fontWeight={300}
                  placeholder="Name"
                  value={childrenForm.name}
                  onChange={(e) =>
                    setChildrenForm({
                      ...childrenForm,
                      name: e.target.value,
                    })
                  }
                />
                {errors.name && (
                  <Text color="crimson" mt={1}>
                    {errors.name}
                  </Text>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.date_of_birth}>
                <FormLabel display={"flex"}>
                  {t("admin__appointments_table__dob")}
                  <Text ml={1} color={"#EE0000"}>
                    *
                  </Text>
                </FormLabel>
                <Input
                  type="date"
                  value={childrenForm.date_of_birth}
                  onChange={(e) =>
                    setChildrenForm({
                      ...childrenForm,
                      date_of_birth: e.target.value,
                    })
                  }
                />
                {errors.date_of_birth && (
                  <Text color="crimson" mt={1}>
                    {errors.date_of_birth}
                  </Text>
                )}
              </FormControl>
              <FormControl mt={4} isInvalid={errors.gender}>
                <FormLabel display={"flex"}>
                  {t("admin__appointments_table__gender")}
                  <Text ml={1} color={"#EE0000"}>
                    *
                  </Text>
                </FormLabel>
                <Select
                  id="status"
                  name="status"
                  className="form-control"
                  isRequired={true}
                  placeholder="Select option"
                  fontSize={"14px"}
                  fontWeight={"400"}
                  borderRadius="4px"
                  value={childrenForm.gender}
                  onChange={(e) => {
                    setChildrenForm({
                      ...childrenForm,
                      gender: e.target.value,
                    });
                  }}
                  mb={5}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Not Disclosed">Not Disclosed</option>
                </Select>
                {errors.gender && (
                  <Text color="crimson" mt={1}>
                    {errors.gender}
                  </Text>
                )}
              </FormControl>
              <FormControl mt={4}>
                <FormLabel display={"flex"}>
                  {t("admin__appointments_table__adopted")}
                  <Text ml={1} color={"#EE0000"}>
                    *
                  </Text>
                </FormLabel>
                <Select
                  id="status"
                  name="status"
                  className="form-control"
                  isRequired={true}
                  placeholder="Select option"
                  fontSize={"14px"}
                  fontWeight={"400"}
                  borderRadius="4px"
                  value={childrenForm.adopted}
                  onChange={(e) => {
                    setChildrenForm({
                      ...childrenForm,
                      adopted: e.target.value,
                    });
                  }}
                  mb={5}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
              </FormControl>
              <FormControl mt={4} isInvalid={errors.email}>
                <FormLabel display={"flex"}>
                  {t("admin__appointments_table__email")}
                </FormLabel>
                <Input
                  fontWeight={300}
                  placeholder="Email"
                  value={childrenForm.email}
                  onChange={(e) =>
                    setChildrenForm({
                      ...childrenForm,
                      email: e.target.value,
                    })
                  }
                />
                {errors.email && (
                  <Text color="crimson" mt={1}>
                    {errors.email}
                  </Text>
                )}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                fontSize={"18px"}
                mx={"auto"}
                color={"#FF6666"}
                bgColor={"#FFEEEE"}
                padding={"8px 16px"}
                borderRadius={"4px"}
                onClick={
                  currentChildId ? handleUpdateChild : handleAddChild
                }
                isDisabled={addingChildren || !validateChildForm}
              >
                {addingChildren ? (
                  <CircularProgress />
                ) : currentChildId ? (
                  t("admin__appointments_table__update_child")
                ) : (
                  t("admin__appointments_table__add_child")
                )}
              </Button>
              <Button
                fontSize={"18px"}
                mx={"auto"}
                color={"#FF6666"}
                bgColor={"#FFEEEE"}
                padding={"8px 16px"}
                borderRadius={"4px"}
                onClick={onChildrenModalClose}
              >
                {t("cancel")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <GenericAlertDialog
        isOpen={isAlertDialogOpen}
        title={alertDialogTitle}
        body={alertDialogBody}
        onClose={handleClose}
        confirm={handleConfirm}
      />
    </Box>
  );
};

function AppointmentStatusLabel(appointment) {
  const { t } = useTranslation();
  let status = t("admin__appointments__in_progress");
  let statusColor = "#199CD6";
  let statusBgColor = "#E4F5FC";

  if (appointment.mediator === null) {
    status = t("admin__appointments__new");
    statusColor = "#FF6666";
    statusBgColor = "#FFEEEE";
  }

  if (appointment.is_closed) {
    status = t("admin__appointments__complete");
    statusColor = "#9FB80A";
    statusBgColor = "#F7F9ED";
  }

  return (
    <Box
      color={statusColor}
      bgColor={statusBgColor}
      borderRadius={"8px"}
      padding={"4px 8px"}
      textAlign={"center"}
      width={"fit-content"}
    >
      {status}
    </Box>
  );
}

export default function AppointmentDetails({
  data,
  collaboratorsData,
  accessToken,
  refresh,
}) {
  return (
    <CRequestDetails
      data={data}
      collaboratorsData={collaboratorsData}
      accessToken={accessToken}
      refresh={refresh}
    />
  );
}
